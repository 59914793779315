import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _10ba8d22 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _7fa3cda1 = () => interopDefault(import('../pages/staff-vote.vue' /* webpackChunkName: "pages/staff-vote" */))
const _511acd5b = () => interopDefault(import('../pages/staff/vote/cast.vue' /* webpackChunkName: "pages/staff/vote/cast" */))
const _c191898c = () => interopDefault(import('../pages/staff/vote/list.vue' /* webpackChunkName: "pages/staff/vote/list" */))
const _c89c15bc = () => interopDefault(import('../pages/articles/edit.vue' /* webpackChunkName: "pages/articles/edit" */))
const _e72c6494 = () => interopDefault(import('../pages/articles/list.vue' /* webpackChunkName: "pages/articles/list" */))
const _537cdc4a = () => interopDefault(import('../pages/authors/edit.vue' /* webpackChunkName: "pages/authors/edit" */))
const _720d2b22 = () => interopDefault(import('../pages/authors/list.vue' /* webpackChunkName: "pages/authors/list" */))
const _57b6e9e4 = () => interopDefault(import('../pages/docs/edit.vue' /* webpackChunkName: "pages/docs/edit" */))
const _486ec278 = () => interopDefault(import('../pages/docs/list.vue' /* webpackChunkName: "pages/docs/list" */))
const _020923e7 = () => interopDefault(import('../pages/episodes/edit.vue' /* webpackChunkName: "pages/episodes/edit" */))
const _1a7e070a = () => interopDefault(import('../pages/episodes/list.vue' /* webpackChunkName: "pages/episodes/list" */))
const _7aa636fc = () => interopDefault(import('../pages/flags/edit.vue' /* webpackChunkName: "pages/flags/edit" */))
const _6b5e0f90 = () => interopDefault(import('../pages/flags/list.vue' /* webpackChunkName: "pages/flags/list" */))
const _3c823f6b = () => interopDefault(import('../pages/frontpage/edit.vue' /* webpackChunkName: "pages/frontpage/edit" */))
const _2cb9f74e = () => interopDefault(import('../pages/hr-department/edit.vue' /* webpackChunkName: "pages/hr-department/edit" */))
const _1d71cfe2 = () => interopDefault(import('../pages/hr-department/list.vue' /* webpackChunkName: "pages/hr-department/list" */))
const _4c6fd100 = () => interopDefault(import('../pages/hr-post/edit.vue' /* webpackChunkName: "pages/hr-post/edit" */))
const _3d27a994 = () => interopDefault(import('../pages/hr-post/list.vue' /* webpackChunkName: "pages/hr-post/list" */))
const _3271cf61 = () => interopDefault(import('../pages/listing-box/edit.vue' /* webpackChunkName: "pages/listing-box/edit" */))
const _2329a7f5 = () => interopDefault(import('../pages/listing-box/list.vue' /* webpackChunkName: "pages/listing-box/list" */))
const _35e01ae2 = () => interopDefault(import('../pages/locks/list.vue' /* webpackChunkName: "pages/locks/list" */))
const _6a613bbb = () => interopDefault(import('../pages/notifications/edit.vue' /* webpackChunkName: "pages/notifications/edit" */))
const _5b19144f = () => interopDefault(import('../pages/notifications/list.vue' /* webpackChunkName: "pages/notifications/list" */))
const _f2af8e2a = () => interopDefault(import('../pages/phone-book/list.vue' /* webpackChunkName: "pages/phone-book/list" */))
const _2079a856 = () => interopDefault(import('../pages/pronounces/edit.vue' /* webpackChunkName: "pages/pronounces/edit" */))
const _3f09f72e = () => interopDefault(import('../pages/pronounces/list.vue' /* webpackChunkName: "pages/pronounces/list" */))
const _30c00872 = () => interopDefault(import('../pages/remote-debug/list.vue' /* webpackChunkName: "pages/remote-debug/list" */))
const _2d0dd29c = () => interopDefault(import('../pages/schedule/list.vue' /* webpackChunkName: "pages/schedule/list" */))
const _a53055a6 = () => interopDefault(import('../pages/screenshot/list.vue' /* webpackChunkName: "pages/screenshot/list" */))
const _6f518e10 = () => interopDefault(import('../pages/statistics/list.vue' /* webpackChunkName: "pages/statistics/list" */))
const _41aad474 = () => interopDefault(import('../pages/super-tags/edit.vue' /* webpackChunkName: "pages/super-tags/edit" */))
const _3262ad08 = () => interopDefault(import('../pages/super-tags/list.vue' /* webpackChunkName: "pages/super-tags/list" */))
const _4abeb9e8 = () => interopDefault(import('../pages/supporters/edit.vue' /* webpackChunkName: "pages/supporters/edit" */))
const _3b76927c = () => interopDefault(import('../pages/supporters/list.vue' /* webpackChunkName: "pages/supporters/list" */))
const _27c311c6 = () => interopDefault(import('../pages/tags/edit.vue' /* webpackChunkName: "pages/tags/edit" */))
const _187aea5a = () => interopDefault(import('../pages/tags/list.vue' /* webpackChunkName: "pages/tags/list" */))
const _bd3a8cc8 = () => interopDefault(import('../pages/unique-group/edit.vue' /* webpackChunkName: "pages/unique-group/edit" */))
const _dbcadba0 = () => interopDefault(import('../pages/unique-group/list.vue' /* webpackChunkName: "pages/unique-group/list" */))
const _7e570eca = () => interopDefault(import('../pages/users/edit.vue' /* webpackChunkName: "pages/users/edit" */))
const _9ce75da2 = () => interopDefault(import('../pages/users/list.vue' /* webpackChunkName: "pages/users/list" */))
const _85dc0b12 = () => interopDefault(import('../pages/votings/edit.vue' /* webpackChunkName: "pages/votings/edit" */))
const _a46c59ea = () => interopDefault(import('../pages/votings/list.vue' /* webpackChunkName: "pages/votings/list" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/login",
    component: _10ba8d22,
    name: "login"
  }, {
    path: "/staff-vote",
    component: _7fa3cda1,
    name: "staff-vote",
    children: [{
      path: "cast",
      component: _511acd5b,
      name: "staff-vote-cast"
    }, {
      path: "list",
      component: _c191898c,
      name: "staff-vote-list"
    }]
  }, {
    path: "/articles/edit",
    component: _c89c15bc,
    name: "articles-edit"
  }, {
    path: "/articles/list",
    component: _e72c6494,
    name: "articles-list"
  }, {
    path: "/authors/edit",
    component: _537cdc4a,
    name: "authors-edit"
  }, {
    path: "/authors/list",
    component: _720d2b22,
    name: "authors-list"
  }, {
    path: "/docs/edit",
    component: _57b6e9e4,
    name: "docs-edit"
  }, {
    path: "/docs/list",
    component: _486ec278,
    name: "docs-list"
  }, {
    path: "/episodes/edit",
    component: _020923e7,
    name: "episodes-edit"
  }, {
    path: "/episodes/list",
    component: _1a7e070a,
    name: "episodes-list"
  }, {
    path: "/flags/edit",
    component: _7aa636fc,
    name: "flags-edit"
  }, {
    path: "/flags/list",
    component: _6b5e0f90,
    name: "flags-list"
  }, {
    path: "/frontpage/edit",
    component: _3c823f6b,
    name: "frontpage-edit"
  }, {
    path: "/hr-department/edit",
    component: _2cb9f74e,
    name: "hr-department-edit"
  }, {
    path: "/hr-department/list",
    component: _1d71cfe2,
    name: "hr-department-list"
  }, {
    path: "/hr-post/edit",
    component: _4c6fd100,
    name: "hr-post-edit"
  }, {
    path: "/hr-post/list",
    component: _3d27a994,
    name: "hr-post-list"
  }, {
    path: "/listing-box/edit",
    component: _3271cf61,
    name: "listing-box-edit"
  }, {
    path: "/listing-box/list",
    component: _2329a7f5,
    name: "listing-box-list"
  }, {
    path: "/locks/list",
    component: _35e01ae2,
    name: "locks-list"
  }, {
    path: "/notifications/edit",
    component: _6a613bbb,
    name: "notifications-edit"
  }, {
    path: "/notifications/list",
    component: _5b19144f,
    name: "notifications-list"
  }, {
    path: "/phone-book/list",
    component: _f2af8e2a,
    name: "phone-book-list"
  }, {
    path: "/pronounces/edit",
    component: _2079a856,
    name: "pronounces-edit"
  }, {
    path: "/pronounces/list",
    component: _3f09f72e,
    name: "pronounces-list"
  }, {
    path: "/remote-debug/list",
    component: _30c00872,
    name: "remote-debug-list"
  }, {
    path: "/schedule/list",
    component: _2d0dd29c,
    name: "schedule-list"
  }, {
    path: "/screenshot/list",
    component: _a53055a6,
    name: "screenshot-list"
  }, {
    path: "/statistics/list",
    component: _6f518e10,
    name: "statistics-list"
  }, {
    path: "/super-tags/edit",
    component: _41aad474,
    name: "super-tags-edit"
  }, {
    path: "/super-tags/list",
    component: _3262ad08,
    name: "super-tags-list"
  }, {
    path: "/supporters/edit",
    component: _4abeb9e8,
    name: "supporters-edit"
  }, {
    path: "/supporters/list",
    component: _3b76927c,
    name: "supporters-list"
  }, {
    path: "/tags/edit",
    component: _27c311c6,
    name: "tags-edit"
  }, {
    path: "/tags/list",
    component: _187aea5a,
    name: "tags-list"
  }, {
    path: "/unique-group/edit",
    component: _bd3a8cc8,
    name: "unique-group-edit"
  }, {
    path: "/unique-group/list",
    component: _dbcadba0,
    name: "unique-group-list"
  }, {
    path: "/users/edit",
    component: _7e570eca,
    name: "users-edit"
  }, {
    path: "/users/list",
    component: _9ce75da2,
    name: "users-list"
  }, {
    path: "/votings/edit",
    component: _85dc0b12,
    name: "votings-edit"
  }, {
    path: "/votings/list",
    component: _a46c59ea,
    name: "votings-list"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/frontpage",
    component: _3c823f6b,
    name: "frontpage_edit"
  }, {
    path: "/statistics",
    component: _6f518e10,
    name: "statistics_list"
  }, {
    path: "/authors",
    component: _720d2b22,
    name: "author_list"
  }, {
    path: "/authors/:slug",
    component: _537cdc4a,
    name: "author_edit"
  }, {
    path: "/live-blogs/:parentSlug/:slug",
    component: _c89c15bc,
    name: "live_blog_child_edit"
  }, {
    path: "/live-blogs",
    component: _e72c6494,
    name: "live_blog_list"
  }, {
    path: "/live-blogs/:slug",
    component: _c89c15bc,
    name: "live_blog_edit"
  }, {
    path: "/longform/:slug",
    component: _c89c15bc,
    name: "longform_edit"
  }, {
    path: "/video/:slug",
    component: _c89c15bc,
    name: "video_edit"
  }, {
    path: "/picture/:slug",
    component: _c89c15bc,
    name: "picture_edit"
  }, {
    path: "/opinion/:slug",
    component: _c89c15bc,
    name: "opinion_edit"
  }, {
    path: "/articles",
    component: _e72c6494,
    name: "article_list"
  }, {
    path: "/articles/:slug",
    component: _c89c15bc,
    name: "article_edit"
  }, {
    path: "/notifications",
    component: _5b19144f,
    name: "notification_list"
  }, {
    path: "/notifications/:id",
    component: _6a613bbb,
    name: "notification_edit"
  }, {
    path: "/docs",
    component: _486ec278,
    name: "doc_list"
  }, {
    path: "/docs/:slug",
    component: _57b6e9e4,
    name: "doc_edit"
  }, {
    path: "/votings",
    component: _a46c59ea,
    name: "voting_list"
  }, {
    path: "/votings/:slug",
    component: _85dc0b12,
    name: "voting_edit"
  }, {
    path: "/staff/vote",
    component: _c191898c,
    name: "staff_vote_list"
  }, {
    path: "/staff/vote/:slug",
    component: _511acd5b,
    name: "staff_vote_cast"
  }, {
    path: "/super-tags",
    component: _3262ad08,
    name: "super_tags_list"
  }, {
    path: "/super-tags/:slug",
    component: _41aad474,
    name: "super_tags_edit"
  }, {
    path: "/tags",
    component: _187aea5a,
    name: "tags_list"
  }, {
    path: "/tags/:slug",
    component: _27c311c6,
    name: "tags_edit"
  }, {
    path: "/flags",
    component: _6b5e0f90,
    name: "flags_list"
  }, {
    path: "/flags/:slug",
    component: _7aa636fc,
    name: "flags_edit"
  }, {
    path: "/users",
    component: _9ce75da2,
    name: "user_list"
  }, {
    path: "/users/:id",
    component: _7e570eca,
    name: "user_edit"
  }, {
    path: "/locks",
    component: _35e01ae2,
    name: "locks"
  }, {
    path: "/remote-debug",
    component: _30c00872,
    name: "remote_debug"
  }, {
    path: "/supporters",
    component: _3b76927c,
    name: "supporter_list"
  }, {
    path: "/supporters/:slug",
    component: _4abeb9e8,
    name: "supporter_edit"
  }, {
    path: "/unique-groups",
    component: _dbcadba0,
    name: "unique_group_list"
  }, {
    path: "/unique-groups/:id",
    component: _bd3a8cc8,
    name: "unique_group_edit"
  }, {
    path: "/schedules",
    component: _2d0dd29c,
    name: "schedule_list"
  }, {
    path: "/screenshots",
    component: _a53055a6,
    name: "screenshot_list"
  }, {
    path: "/listing-boxes",
    component: _2329a7f5,
    name: "listing_box_list"
  }, {
    path: "/listing-boxes/:slug",
    component: _3271cf61,
    name: "listing_box_edit"
  }, {
    path: "/episodes",
    component: _1a7e070a,
    name: "episode_list"
  }, {
    path: "/episodes/:slug",
    component: _020923e7,
    name: "episode_edit"
  }, {
    path: "/hr-departments",
    component: _1d71cfe2,
    name: "hr_department_list"
  }, {
    path: "/hr-departments/:slug",
    component: _2cb9f74e,
    name: "hr_department_edit"
  }, {
    path: "/hr-posts",
    component: _3d27a994,
    name: "hr_post_list"
  }, {
    path: "/hr-posts/:slug",
    component: _4c6fd100,
    name: "hr_post_edit"
  }, {
    path: "/phone-book",
    component: _f2af8e2a,
    name: "phone_book"
  }, {
    path: "/pronounces",
    component: _3f09f72e,
    name: "pronounce_list"
  }, {
    path: "/pronounces/:slug",
    component: _2079a856,
    name: "pronounce_edit"
  }, {
    path: "/az-ev-tartalomipari-dolgozoja-szavazas",
    component: _7fa3cda1,
    name: "staff-vote"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
