export default ($config, $securityRepository, $auth) => ({
  options: {
    endpoints: {
      authorization: 'https://accounts.google.com/o/oauth2/auth',
    },
    redirectPath: '/login',
  },

  login() {
    const opts = this.getOpts()
    const url = this.options.endpoints.authorization + '?' + this.encodeQuery(opts)
    window.location.replace(url)
  },

  async handleGoogleResponse(code) {
    try {
      const response = await $securityRepository.checkGoogleToken(code, this.getRedirectUrl())
      $auth.handleTokenAccess(response.token, response.refreshToken)
      return true
    } catch (e) {
      console.error(e)
    }

    return false
  },

  getOpts() {
    return {
      access_type: null,
      client_id: $config.googleOAuthClientId,
      code_challenge_method: '',
      protocol: 'oauth2',
      redirect_uri: this.getRedirectUrl(),
      response_type: 'code',
      scope: 'openid profile email',
      state: this.randomString(10),
    }
  },

  getRedirectUrl() {
    const base = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '')
    return base + '' + this.options.redirectPath
  },

  randomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let result = ''
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  },

  encodeQuery(queryObject) {
    return Object.entries(queryObject).filter(([_key, value]) => typeof value !== 'undefined').map(([key, value]) => encodeURIComponent(key) + (value != null ? '=' + encodeURIComponent(value) : '')).join('&')
  },
})
