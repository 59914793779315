
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      loggedInUser: 'auth/getLoggedInUser',
    }),
  },
}
