
export default {
  computed: {
    showShortCutModal: {
      get() {
        return this.$store.getters['layout/isShowShortCutModal']
      },
      set(newValue) {
        this.$store.commit('layout/setShowShortCutModal', newValue)
      },
    },
  },
}
