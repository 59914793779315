export default $axios => ({

  sendToken(payLoad) {
    return $axios.$post('/forgot-password/', payLoad)
  },

  validateToken(token) {
    return $axios.$get('/forgot-password/' + token)
  },

  resetPassword(token, payLoad) {
    return $axios.$post('/forgot-password/' + token, payLoad)
  },

})
