
export default {
  props: {
    progress: {
      type: Number,
      default: null,
    },
    size: {
      type: Number,
      default: 200,
    },
    emptyColor: {
      type: String,
      default: '#e6e9f0',
    },
  },
  data() {
    return {
      gradient: {
        radial: false,
        colors: [
          {
            color: '#135',
            offset: '0',
            opacity: '1',
          },
          {
            color: '#fe0',
            offset: '100',
            opacity: '1',
          },
        ],
      },
    }
  },
}
