export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    handleEsc(event) {
      if (event.trigger === 'esc' || event.trigger === 'backdrop') {
        event.preventDefault()
        this.close()
      }
    },
  },
}
