import { lockEvents as Event } from '~/helpers/lockEvents'

export const state = () => ({
  locks: {},
  socketInited: false,
})

export const mutations = {
  setLocks(state, locks) {
    state.locks = locks
  },

  updateLockItem(state, message) {
    if (state.locks === null) {
      state.locks = {}
    }

    const locks = JSON.parse(JSON.stringify(state.locks))

    if (message.event.type === Event.DELETED) {
      console.log('DELETE EVENT')
      delete locks[message.page]
      state.locks = locks
      return
    }

    locks[message.page] = message.lock
    state.locks = locks
  },
  socketInited(state, socketInited) {
    state.socketInited = socketInited
  },
}

export const actions = {
  async init({ commit, state }, payload) {
    console.log('store/lock, init run')

    // Get the locks
    const response = await window.$nuxt.$lockManager.getLocks()
    commit('setLocks', response)
  },

  async deleteByPage({ commit, state }, page) {
    console.log('store/lock, deleteByPage run', page)

    // Delete the lock
    return await window.$nuxt.$lockManager.deleteLockByPage(page)
  },

  async initSocket({ commit }) {
    console.log('init lockSocket')
    await window.$nuxt.$lockManager.initSocket()
    commit('socketInited', true)
  },

  async closeSocket({ commit }) {
    console.log('close lock socket')
    await window.$nuxt.$lockManager.closeSocket()
    commit('socketInited', false)
  },
}

export const getters = {
  locks(state) {
    return state.locks
  },

  isSocketInited(state) {
    return state.socketInited
  },
}
