
import modals from '~/mixins/modals'

export default {
  mixins: [modals],
  props: {
    disableConfirm: {
      type: Boolean,
      default: false,
    },
    hideCancel: {
      type: Boolean,
      default: false,
    },
    hideConfirm: {
      type: Boolean,
      default: false,
    },
    cancelText: {
      type: String,
      default: 'Mégse',
    },
    confirmText: {
      type: String,
      default: 'Igen',
    },
    autoCloseOnConfirm: {
      type: Boolean,
      default: true,
    },
    question: {
      type: String,
      default: 'Biztos vagy benne?',
    },
    description: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: 'md',
    },
  },
  methods: {
    confirm() {
      this.$emit('confirm')
      if (this.autoCloseOnConfirm) {
        this.close()
      }
    },
  },
}
