export default $axios => ({
  firstFactor(email, password) {
    return $axios.$post('/api/login', {
      email, password,
    })
  },

  secondFactor(jwt, code) {
    return $axios.$post(
      '/api/login/otp/verify',
      {
        code,
      },
      {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      },
    )
  },

  checkGoogleToken(code, redirectUri) {
    const formData = new FormData()
    formData.append('code', code)
    formData.append('redirect_uri', redirectUri)

    return $axios.$post('/api/login/google/check', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },

  getMe() {
    return $axios.$get('/api/me')
  },

  refreshToken(token) {
    return $axios.$post('/api/token/refresh', { refreshToken: token })
  },

  getSecret() {
    return $axios.$get('/api/login/otp/secret')
  },

  forceNewSecret() {
    return $axios.$get('/api/login/otp/secret?force_new_code=true')
  },

  getBackupCode(userId) {
    return $axios.$get('/api/login/otp/backup-code', {
      params: {
        userId,
      },
    })
  },
})
