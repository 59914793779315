
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      checked: false,
    }
  },
  computed: {
    ...mapGetters({
      loggedInUserName: 'auth/getLoggedInUserName',
    }),
    showScreenshotModal: {
      get() {
        return this.$store.getters['layout/isShowScreenshotModal']
      },
      set(newValue) {
        this.$store.commit('layout/setShowScreenshotModal', newValue)
      },
    },
    showPersonOnDutyModal: {
      get() {
        return this.$store.getters['layout/isShowPersonOnDutyModal']
      },
      set(newValue) {
        this.$store.commit('layout/setShowPersonOnDutyModal', newValue)
      },
    },
    showServiceRequestModal: {
      get() {
        return this.$store.getters['layout/isShowServiceRequestModal']
      },
      set(newValue) {
        this.$store.commit('layout/setShowServiceRequestModal', newValue)
      },
    },
    showShortCutModal: {
      get() {
        return this.$store.getters['layout/isShowShortCutModal']
      },
      set(newValue) {
        this.$store.commit('layout/setShowShortCutModal', newValue)
      },
    },
    showStat: {
      get() {
        return this.$store.getters.getSettings?.showStat
      },
      set(newValue) {
        console.log('SSSSSET SHOWSTAT', newValue)
        this.$store.commit('setSettingsField', { field: 'showStat', value: newValue })
      },
    },
    showCookieClear: {
      get() {
        return this.$store.getters['layout/isShowClearCookie']
      },
      set(newValue) {
        this.$store.commit('layout/setShowClearCookie', newValue)
      },
    },
  },
  methods: {
    toggleShowStat() {
      this.showStat = !this.showStat
    },
    async logout() {
      await this.$auth.logout()
      this.$emit('logoutSuccess')
      await this.$router.push({
        path: '/login',
      })
    },
  },
}
