module.exports = {
  getTypedImage(src, type) {
    if (!src) {
      return null
    }

    if (!type) {
      return src
    }
    const url = new URL(src)

    /*
     * Ha van már asset típusa, pl.:
     * https://images.telex-assets.hu/cikkek/123_cikktorzs.jpg
     */
    const match = url.pathname.match(/([^/]*)\/*$/)[1].match(/.*_(.*).(jpg|jpeg|gif|png)$/)
    if (match && match[1]) {
      return url.origin + url.pathname.replace(new RegExp('_' + match[1]), '_' + type)
    }

    /*
     * Ha nincs még asset típusa, pl.:
     * https://images.telex-assets.hu/cikkek/123.jpg
     */
    const fileNameMatch = url.pathname.match(/([^/]*)\/*$/)[1].match(/(.*)(.jpg|.jpeg|.gif|.png)$/)
    return url.origin + url.pathname.replace(fileNameMatch[1], fileNameMatch[1] + '_' + type)
  },

  getSrcSet(src) {
    const srcsetItems = []

    for (let i = 1; i++ < 4;) {
      srcsetItems.push(src.replace(/(.(png|jpeg|jpg|gif))$/g, '@' + i + 'x$1 ' + i + 'x'))
    }

    return srcsetItems.join(', ')
  },

  getOrigImage(src) {
    const url = new URL(src)
    const match = url.pathname.match(/([^/]*)\/*$/)[1].match(/.*_(.*).(jpg|jpeg|gif|png)$/)
    return url.origin + url.pathname
      .replace(new RegExp('_' + match[1]), '')
      .replace('/images', '')
  },

  getUploadsPath(path) {
    if (!path || path === '' || path.startsWith('/uploads')) {
      return path
    }
    if (path.match(/^\/images\/(.*)\/(.*)_[a-zA-Z0-9._:@-]+\.(jpg|jpeg|png|gif)$/)) {
      const match = path.match(/^\/images\/(.*)\/(.*)_[a-zA-Z0-9._:@-]+\.(jpg|jpeg|png|gif)$/)
      return `/uploads/images/${match[1]}/${match[2]}.${match[3]}`
    }
    if (path.match(/^\/images\/(.*)\/(.*)\.(jpg|jpeg|png|gif)$/)) {
      const match = path.match(/^\/images\/(.*)\/(.*)\.(jpg|jpeg|png|gif)$/)
      return `/uploads/images/${match[1]}/${match[2]}.${match[3]}`
    }
    return ''
  },
}
