
import SidebarItem from './SidebarItem'
import PermissionCheck from '~/components/Shared/PermissionCheck'

export default {
  name: 'SideBar',
  components: { PermissionCheck, SidebarItem },
  data() {
    const isOpen = this.$store.getters['sidebar/getSidebarState']
    return {
      isOpen,
    }
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen
      this.$store.commit('sidebar/setState', this.isOpen)
    },
  },
}
