export default $axios => ({

  getList(page = 1, perPage = 20, orderBy = 'id', orderDesc = false, filterQuery = {}) {
    return $axios.$get('/api/pronounces', {
      params: {
        page,
        perPage,
        orderBy,
        orderType: orderDesc === false ? 'ASC' : 'DESC',
        ...filterQuery,
      },
    })
  },

  getAll() {
    return $axios.$get('/api/pronounces', {
      params: {
        all: true,
      },
    })
  },

  getBySlug(slug) {
    return $axios.$get('/api/pronounces/' + slug)
  },

  getByName(original) {
    return $axios.$get('/api/pronounces/name/' + original)
  },

  async post(postData) {
    return await $axios.$post('/api/pronounces', postData)
  },

})
