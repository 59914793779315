
import { mapState } from 'vuex'

export default {
  props: {
    propShow: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      tabId: 'tabId',
    }),
    showServiceRequestModal: {
      get() {
        return this.$store.getters['layout/isShowServiceRequestModal']
      },
      set(newValue) {
        this.$store.commit('layout/setShowServiceRequestModal', newValue)
      },
    },
  },
  data() {
    return {
      show: false,
      serviceMessage: '',
    }
  },
  watch: {
    propShow(newValue) {
      this.show = newValue
    },
  },
  methods: {
    serviceRequest() {
      this.$sentry.captureMessage(this.$store.getters['auth/getLoggedInUser'].lastName + ' '
          + this.$store.getters['auth/getLoggedInUser'].firstName + ': ' + this.serviceMessage)
      this.serviceMessage = ''
      this.$toast.success('Üzenet elküldve, érkezik a segítség!', { duration: 1200 })
      this.$emit('close')
    },
  },
}
