import { render, staticRenderFns } from "./MyMenu.vue?vue&type=template&id=8fa681e4"
import script from "./MyMenu.vue?vue&type=script&lang=js"
export * from "./MyMenu.vue?vue&type=script&lang=js"
import style0 from "./MyMenu.vue?vue&type=style&index=0&id=8fa681e4&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PermissionCheck: require('/app/components/Shared/PermissionCheck.vue').default})
