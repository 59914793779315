import { lockEvents as Event } from '../../helpers/lockEvents'

/**
 * A store felől jövő requestek kiküldése a socketre, és az onnan jövő üzenetek beírására az osztály feladata
 */
export class LockManager {
  constructor(ctx, tabId) {
    this.ctx = ctx
    this.store = ctx.$store
    this.tabId = tabId
    this.bus = ctx.$bus
  }

  async initSocket() {
    this.socket = await this.ctx.$nuxtSocket({
      channel: '/lock',
      persist: 'lockSocket',
      reconnection: true,
      teardown: false,
      auth: {
        token: this.store.getters['auth/getAccessToken'],
        tabId: this.tabId,
      },
    })

    this.socket.on('pageLockChanged', (message, _) => {
      // console.log('pageLockChanged; message: ', message.page, message.lock.updatedAt, message.lock?.messageId, message.lock.user?.email)
      // Az általunk lockolt oldalra frissítjük a state-et
      if (message.page === this.store.state.pageLock.page) {
        if (message.event.type === Event.FORCE_UPDATE
          || (message.event.type === Event.CREATED
             && message.lock.user.id === this.store.getters['auth/getLoggedInUser'].id
             && !message.lock.tabIds.includes(this.tabId)
          )) {
          this.setLockOnPage(message.page, message.lock.entity, false)
        }
        this.store.commit('pageLock/setLockData', message.lock)
        if (message.event.type === Event.FORCE_UPDATE
          && message.lock.user.id === this.store.getters['auth/getLoggedInUser'].id
        ) {
          console.log('FORCE_UPDATE!', message.event.data)
          this.bus.$emit('lock-force-updated', message.event.data)
        }
      }

      // A locks-ban ahol minden lockot gyűjtünk befrissítjuk a változást
      this.store.commit('locks/updateLockItem', message)
    })

    this.socket.on('reconnect', () => {
      console.log('RECONNECTED!')
      this.store.dispatch('pageLock/setSocketConnected')

      this.restartConnection(this.store.state.pageLock.page).then((response) => {
        this.store.commit('pageLock/setLockData', response)
      })
    })

    this.socket.on('connected', () => {
      console.log('socket connected!')
      this.store.dispatch('pageLock/setSocketConnected')
    })

    this.socket.on('disconnect', () => {
      this.store.dispatch('pageLock/setSocketDisconnected')
      console.log('Lock disconnected!', this.socket.connected)
    })

    this.store.dispatch('locks/init')
  }

  async emit(event, message = null) {
    if (!this.socket) {
      console.log('socket is not inited yet! request dropped;')
      return
    }

    return await this.store.dispatch(
      '$nuxtSocket/emit',
      {
        socket: this.socket,
        evt: event,
        msg: message,
      },
    )
  }

  async restartConnection(page) {
    const response = await this.emit('restartConnection', { page })
    console.log('reconnect response: ', response)
    return response
  }

  async getLockByPage(page) {
    const response = await this.emit('getLockByPage', { page })
    console.log('getLockByPage response: ', response)
    return response
  }

  async getLocks() {
    const response = await this.emit('getLocks')
    console.log('getLocks response: ', response)
    return response
  }

  async setLockOnPage(page, entity, acquire) {
    return await this.emit(
      'lockPage',
      {
        page,
        user: this.store.getters['auth/getLoggedInUser'],
        tabId: this.tabId,
        entity: { ...entity, source: this.tabId },
        acquire,
      },
    )
  }

  async releaseLockOnPage(page, tabOnly = true) {
    const response = await this.emit(
      'releaseLockByPage',
      {
        page,
        user: this.store.getters['auth/getLoggedInUser'],
        tabId: tabOnly ? this.tabId : null,
      },
    )
    console.log('releaseLockOnPage response: ', response)

    return response
  }

  async deleteLockByPage(page) {
    const response = await this.emit('deleteLockByPage', { page })
    console.log('deleteLockByPage response: ', response)

    return response
  }

  closeSocket() {
    if (this.socket) {
      this.socket.disconnect()
    }
  }
}
