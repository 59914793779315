import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_54604f30 from 'nuxt_plugin_plugin_54604f30' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_bootstrapvue_f9ee0b16 from 'nuxt_plugin_bootstrapvue_f9ee0b16' // Source: ./bootstrap-vue.js (mode: 'all')
import nuxt_plugin_sentryserver_4de1dc0a from 'nuxt_plugin_sentryserver_4de1dc0a' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_2faf2afc from 'nuxt_plugin_sentryclient_2faf2afc' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_cookieuniversalnuxt_674415ee from 'nuxt_plugin_cookieuniversalnuxt_674415ee' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_nuxtleaflet_97ac5882 from 'nuxt_plugin_nuxtleaflet_97ac5882' // Source: ./nuxt-leaflet.js (mode: 'client')
import nuxt_plugin_nuxtsocketio_1bb55848 from 'nuxt_plugin_nuxtsocketio_1bb55848' // Source: ./nuxt-socket-io.js (mode: 'all')
import nuxt_plugin_vuescrollto_40fb4903 from 'nuxt_plugin_vuescrollto_40fb4903' // Source: ./vue-scrollto.js (mode: 'client')
import nuxt_plugin_toast_e867e216 from 'nuxt_plugin_toast_e867e216' // Source: ./toast.js (mode: 'client')
import nuxt_plugin_moment_42881812 from 'nuxt_plugin_moment_42881812' // Source: ./moment.js (mode: 'all')
import nuxt_plugin_axios_80c87b74 from 'nuxt_plugin_axios_80c87b74' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_browserdetectplugin_08b96c78 from 'nuxt_plugin_browserdetectplugin_08b96c78' // Source: ./browser-detect-plugin.js (mode: 'client')
import nuxt_plugin_axios_3566aa80 from 'nuxt_plugin_axios_3566aa80' // Source: ../plugins/axios (mode: 'all')
import nuxt_plugin_repositories_3f2ec844 from 'nuxt_plugin_repositories_3f2ec844' // Source: ../plugins/repositories (mode: 'all')
import nuxt_plugin_auth_1f843884 from 'nuxt_plugin_auth_1f843884' // Source: ../plugins/login/auth (mode: 'all')
import nuxt_plugin_bus_5e46274a from 'nuxt_plugin_bus_5e46274a' // Source: ../plugins/bus (mode: 'client')
import nuxt_plugin_commondata_4b9aa116 from 'nuxt_plugin_commondata_4b9aa116' // Source: ../plugins/common-data (mode: 'all')
import nuxt_plugin_vueselect_0c6a19ce from 'nuxt_plugin_vueselect_0c6a19ce' // Source: ../plugins/vue-select (mode: 'all')
import nuxt_plugin_qrcode_05a0a1e3 from 'nuxt_plugin_qrcode_05a0a1e3' // Source: ../plugins/qr-code (mode: 'all')
import nuxt_plugin_helpers_16d71a4f from 'nuxt_plugin_helpers_16d71a4f' // Source: ../plugins/helpers (mode: 'all')
import nuxt_plugin_typedimage_f42245fc from 'nuxt_plugin_typedimage_f42245fc' // Source: ../plugins/typed-image (mode: 'all')
import nuxt_plugin_vueellipseprogress_42d52119 from 'nuxt_plugin_vueellipseprogress_42d52119' // Source: ../plugins/vue-ellipse-progress (mode: 'client')
import nuxt_plugin_vuectkdatetimepicker_20b2be9f from 'nuxt_plugin_vuectkdatetimepicker_20b2be9f' // Source: ../plugins/vue-ctk-date-time-picker (mode: 'client')
import nuxt_plugin_managers_9004b968 from 'nuxt_plugin_managers_9004b968' // Source: ../plugins/socket/managers (mode: 'client')
import nuxt_plugin_vuecroppie_078e327f from 'nuxt_plugin_vuecroppie_078e327f' // Source: ../plugins/vue-croppie (mode: 'client')
import nuxt_plugin_vuetagsinput_eb545e9a from 'nuxt_plugin_vuetagsinput_eb545e9a' // Source: ../plugins/vue-tags-input (mode: 'client')
import nuxt_plugin_vuejsonpretty_78fbc604 from 'nuxt_plugin_vuejsonpretty_78fbc604' // Source: ../plugins/vue-json-pretty (mode: 'all')
import nuxt_plugin_vjsoneditor_f76ee136 from 'nuxt_plugin_vjsoneditor_f76ee136' // Source: ../plugins/v-json-editor (mode: 'client')
import nuxt_plugin_globalcomponentloader_526d2a00 from 'nuxt_plugin_globalcomponentloader_526d2a00' // Source: ../plugins/global-component-loader.js (mode: 'all')
import nuxt_plugin_usersettings_5ebf0930 from 'nuxt_plugin_usersettings_5ebf0930' // Source: ../plugins/user-settings.js (mode: 'all')
import nuxt_plugin_vueshortkey_7ae76292 from 'nuxt_plugin_vueshortkey_7ae76292' // Source: ../plugins/vue-shortkey.js (mode: 'client')
import nuxt_plugin_vuefileagent_1a60b1de from 'nuxt_plugin_vuefileagent_1a60b1de' // Source: ../plugins/vue-file-agent (mode: 'client')
import nuxt_plugin_googlelogin_22f3d1b1 from 'nuxt_plugin_googlelogin_22f3d1b1' // Source: ../plugins/login/google-login (mode: 'all')
import nuxt_plugin_permissionchecker_570b38da from 'nuxt_plugin_permissionchecker_570b38da' // Source: ../plugins/login/permission-checker (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"htmlAttrs":{"lang":"hu","translate":"no","class":"notranslate"},"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":""},{"hid":"google","name":"google","content":"notranslate"}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"}],"style":[],"script":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_54604f30 === 'function') {
    await nuxt_plugin_plugin_54604f30(app.context, inject)
  }

  if (typeof nuxt_plugin_bootstrapvue_f9ee0b16 === 'function') {
    await nuxt_plugin_bootstrapvue_f9ee0b16(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_sentryserver_4de1dc0a === 'function') {
    await nuxt_plugin_sentryserver_4de1dc0a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_2faf2afc === 'function') {
    await nuxt_plugin_sentryclient_2faf2afc(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_674415ee === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_674415ee(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_nuxtleaflet_97ac5882 === 'function') {
    await nuxt_plugin_nuxtleaflet_97ac5882(app.context, inject)
  }

  if (typeof nuxt_plugin_nuxtsocketio_1bb55848 === 'function') {
    await nuxt_plugin_nuxtsocketio_1bb55848(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuescrollto_40fb4903 === 'function') {
    await nuxt_plugin_vuescrollto_40fb4903(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_toast_e867e216 === 'function') {
    await nuxt_plugin_toast_e867e216(app.context, inject)
  }

  if (typeof nuxt_plugin_moment_42881812 === 'function') {
    await nuxt_plugin_moment_42881812(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_80c87b74 === 'function') {
    await nuxt_plugin_axios_80c87b74(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_browserdetectplugin_08b96c78 === 'function') {
    await nuxt_plugin_browserdetectplugin_08b96c78(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_3566aa80 === 'function') {
    await nuxt_plugin_axios_3566aa80(app.context, inject)
  }

  if (typeof nuxt_plugin_repositories_3f2ec844 === 'function') {
    await nuxt_plugin_repositories_3f2ec844(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_1f843884 === 'function') {
    await nuxt_plugin_auth_1f843884(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_bus_5e46274a === 'function') {
    await nuxt_plugin_bus_5e46274a(app.context, inject)
  }

  if (typeof nuxt_plugin_commondata_4b9aa116 === 'function') {
    await nuxt_plugin_commondata_4b9aa116(app.context, inject)
  }

  if (typeof nuxt_plugin_vueselect_0c6a19ce === 'function') {
    await nuxt_plugin_vueselect_0c6a19ce(app.context, inject)
  }

  if (typeof nuxt_plugin_qrcode_05a0a1e3 === 'function') {
    await nuxt_plugin_qrcode_05a0a1e3(app.context, inject)
  }

  if (typeof nuxt_plugin_helpers_16d71a4f === 'function') {
    await nuxt_plugin_helpers_16d71a4f(app.context, inject)
  }

  if (typeof nuxt_plugin_typedimage_f42245fc === 'function') {
    await nuxt_plugin_typedimage_f42245fc(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueellipseprogress_42d52119 === 'function') {
    await nuxt_plugin_vueellipseprogress_42d52119(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuectkdatetimepicker_20b2be9f === 'function') {
    await nuxt_plugin_vuectkdatetimepicker_20b2be9f(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_managers_9004b968 === 'function') {
    await nuxt_plugin_managers_9004b968(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuecroppie_078e327f === 'function') {
    await nuxt_plugin_vuecroppie_078e327f(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuetagsinput_eb545e9a === 'function') {
    await nuxt_plugin_vuetagsinput_eb545e9a(app.context, inject)
  }

  if (typeof nuxt_plugin_vuejsonpretty_78fbc604 === 'function') {
    await nuxt_plugin_vuejsonpretty_78fbc604(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vjsoneditor_f76ee136 === 'function') {
    await nuxt_plugin_vjsoneditor_f76ee136(app.context, inject)
  }

  if (typeof nuxt_plugin_globalcomponentloader_526d2a00 === 'function') {
    await nuxt_plugin_globalcomponentloader_526d2a00(app.context, inject)
  }

  if (typeof nuxt_plugin_usersettings_5ebf0930 === 'function') {
    await nuxt_plugin_usersettings_5ebf0930(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueshortkey_7ae76292 === 'function') {
    await nuxt_plugin_vueshortkey_7ae76292(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vuefileagent_1a60b1de === 'function') {
    await nuxt_plugin_vuefileagent_1a60b1de(app.context, inject)
  }

  if (typeof nuxt_plugin_googlelogin_22f3d1b1 === 'function') {
    await nuxt_plugin_googlelogin_22f3d1b1(app.context, inject)
  }

  if (typeof nuxt_plugin_permissionchecker_570b38da === 'function') {
    await nuxt_plugin_permissionchecker_570b38da(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
