const middleware = {}

middleware['frontend-url-redirect'] = require('../middleware/frontend-url-redirect.js')
middleware['frontend-url-redirect'] = middleware['frontend-url-redirect'].default || middleware['frontend-url-redirect']

middleware['last-url'] = require('../middleware/last-url.js')
middleware['last-url'] = middleware['last-url'].default || middleware['last-url']

middleware['old-admin-redirect'] = require('../middleware/old-admin-redirect.js')
middleware['old-admin-redirect'] = middleware['old-admin-redirect'].default || middleware['old-admin-redirect']

middleware['permission-check'] = require('../middleware/permission-check.js')
middleware['permission-check'] = middleware['permission-check'].default || middleware['permission-check']

middleware['route-to-state'] = require('../middleware/route-to-state.js')
middleware['route-to-state'] = middleware['route-to-state'].default || middleware['route-to-state']

middleware['version-change-force-reload'] = require('../middleware/version-change-force-reload.js')
middleware['version-change-force-reload'] = middleware['version-change-force-reload'].default || middleware['version-change-force-reload']

export default middleware
