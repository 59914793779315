
import moment from 'moment/moment'

export default {
  data() {
    return {
      personsOfDutyScheduleItems: {
        6: { husz: null, tmk: null },
        0: { husz: null, tmk: null },
        1: { husz: null, tmk: null },
        2: { husz: null, tmk: null },
        3: { husz: null, tmk: null },
        4: { husz: null, tmk: null },
        5: { husz: null, tmk: null },
      },
      days: [6, 0, 1, 2, 3, 4, 5],
      ownDuty: [],
      today: new Date(),
    }
  },
  computed: {
    showPersonOnDutyModal: {
      get() {
        return this.$store.getters['layout/isShowPersonOnDutyModal']
      },
      set(newValue) {
        this.$store.commit('layout/setShowPersonOnDutyModal', newValue)
      },
    },
  },
  mounted() {
    this.$scheduleItemRepository.getScheduleItems(
      moment(new Date()).set({ hour: 0, minute: 0, second: 0 }).unix(),
      moment(new Date()).add(6, 'days').set({ hour: 23, minute: 59, second: 59 }).unix(),
      false,
      null,
      ['husz', 'tmk'],
    ).then((response) => {
      response.forEach((scheduleItem) => {
        this.personsOfDutyScheduleItems[this.$moment.unix(scheduleItem.from).toDate().getDay()][scheduleItem.schedule.slug] = scheduleItem
      })
    }).catch(() => console.log('Nem sikerült lekérdezni az ügyeletesek listáját'))
    this.$scheduleItemRepository.getScheduleItems(
      moment(new Date()).set({ hour: 0, minute: 0, second: 0 }).unix(),
      moment(new Date()).add(6, 'days').set({ hour: 23, minute: 59, second: 59 }).unix(),
      false,
      this.$store.getters['auth/getLoggedInUser'].id,
    ).then((response) => {
      this.ownDuty = response
      return response
    }).catch(() => console.log('Nem sikerült lekérdezni a saját beosztásod'))
  },
  methods: {
    getOrdinalNumberOfNexDays(numberOfDays) {
      const ordinalNumberOfNextDays = []
      for (let i = 0; i < numberOfDays; i++) {
        const date = new Date()
        date.setDate(date.getDate() + i)
        ordinalNumberOfNextDays.push(date.getDay())
      }

      return ordinalNumberOfNextDays
    },
    getDayNameByNumber(number) {
      switch (number) {
        case 1:
          return 'hétfő'
        case 2:
          return 'kedd'
        case 3:
          return 'szerda'
        case 4:
          return 'csütörtök'
        case 5:
          return 'péntek'
        case 6:
          return 'szombat'
        default:
          return 'vasárnap'
      }
    },
  },
}
