export const state = () => ({
  frontendSetup: null,
  componentSetup: null,
  exchangeRates: null,
  weatherData: null,
  pipStatus: null,
  dwPromo: null,
})

export const mutations = {
  setFrontendSetup(state, newValue) {
    state.frontendSetup = newValue
  },
  setComponentSetup(state, newValue) {
    state.componentSetup = newValue
  },
  setExchangeRates(state, newValue) {
    state.exchangeRates = newValue
  },
  setWeatherData(state, newValue) {
    state.weatherData = newValue
  },
  setPipStatus(state, newValue) {
    state.pipStatus = newValue
  },
  setDwPromo(state, newValue) {
    state.dwPromo = newValue
  },
}

export const getters = {
  getFrontendSetup(state) {
    return state.frontendSetup
  },
  getComponentSetup(state) {
    return state.componentSetup
  },
  getExchangeRates(state) {
    return state.exchangeRates
  },
  getWeatherData(state) {
    return state.weatherData
  },
  getPipStatus(state) {
    return state.pipStatus
  },
  getDwPromo(state) {
    return state.dwPromo
  },
}
