export default $axios => ({
  async saveQuiz(quizToSave) {
    const quizId = quizToSave._id ?? null

    const quizResponse = await $axios.$post(
      '/api/quiz',
      {
        variables: {
          questions: quizToSave.questions,
          quizId,
        },
        query: `
          mutation($quizId: String, $questions: [QuestionInput]) {
            addOrUpdateQuiz(
              _id: $quizId  
              title: "valami"
              questions: $questions
            ) {
              _id
              title
              slug
              questions {
                _id
                question
                description
                slug
                defaultAnswer
                activeTo
                displayAs
                numberOfAllowedAnswers
                image
                rightAnswer {
                  public
                  type
                  value
                }
                options {
                  _id
                  title
                }
                answers {
                  _id
                  value
                  title
                  description
                  image
                }
              }
            }
          }`,
      },
    )

    return quizResponse
  },

  async getQuiz(quizId) {
    return await $axios.$post(
      '/api/quiz',
      {
        query: `
          query {
            quiz(quizId: "${quizId}") {
              title
              slug
              _id
              questions {
                question
                description
                image
                slug
                activeTo
                defaultAnswer
                numberOfAllowedAnswers
                displayAs
                _id
                rightAnswer {
                  public
                  type
                  value
                }
                options {
                  _id
                  title
                }
                answers {
                  _id
                  value
                  title
                  image
                  description
                }
              }
            }
          }`,
      },
    )
  },
})
