
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      broadcastTypes: [
        { label: 'Kisüzem', value: 'default' },
        { label: 'Fontos', value: 'message' },
        { label: 'Hiba', value: 'error' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      broadcast: 'getBroadcast',
    }),
    showBroadcastModal: {
      get() {
        return this.$store.getters['layout/isShowBroadcastModal']
      },
      set(newValue) {
        this.$store.commit('layout/setShowBroadcastModal', newValue)
      },
    },
  },
  watch: {
    broadcast(newValue) {
      this.broadcastData = { ...newValue }
    },
  },
}
