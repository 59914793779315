export default function({ route, store, redirect, next, $permissionChecker }) {
  // Ha nincs belépve és épp a login oldalra tart (pl. kilépés)
  if (!store.getters['auth/getLoggedInUser']) {
    if (route.name === 'login') {
      return
    }

    redirect('/login')
    return
  }

  $permissionChecker.checkRouteAccess(route)
}
