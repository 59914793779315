export default $axios => ({
  getList(page = 1, perPage = 20, orderBy = 'name', orderDesc = false, filterQuery = {}) {
    return $axios.$get('/api/super-tags', {
      params: {
        page,
        perPage,
        orderBy,
        orderType: orderDesc === false ? 'ASC' : 'DESC',
        ...filterQuery,
      },
    })
  },

  getAll(filterQuery = {}) {
    return $axios.$get('/api/super-tags', {
      params: {
        all: true,
        ...filterQuery,
      },
    })
  },

  getBySlug(slug) {
    return $axios.$get('/api/super-tags/' + slug)
  },

  getTypeahead(typed, limit = 5) {
    return $axios.$get('/api/search/super-tags?limit=' + limit + '&term=' + typed, { progress: false })
  },

  async post(postData) {
    return await $axios.$post('/api/super-tags', postData)
  },
})
