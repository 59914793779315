
export default {
  props: {
    path: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    iconClass: {
      type: String,
      default: '',
    },
    isOpen: {
      type: Boolean,
    },
  },
  computed: {
    iconId() {
      return 'menu-item-' + this.iconClass
    },
    currentPath() {
      return this.$nuxt.$route.path
    },
    isActive() {
      const currentPath = this.$nuxt.$route.path + '/'
      return currentPath.startsWith(this.path + '/')
    },
  },
  watch: {
    isOpen(oldValue, newValue) {
      this.toggleTooltips(!newValue)
    },
  },
  mounted() {
    const self = this
    setTimeout(function() {
      self.toggleTooltips(self.isOpen)
    }, 300)
  },
  methods: {
    toggleTooltips(isOpen) {
      if (isOpen) {
        // console.log('toggleTooltips run, disable them!')
        this.$root.$emit('bv::hide::tooltip', this.iconId)
        this.$root.$emit('bv::disable::tooltip', this.iconId)
      } else {
        // console.log('toggleTooltips run, enable them!')
        this.$root.$emit('bv::enable::tooltip', this.iconId)
      }
    },
  },
}
