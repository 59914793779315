
export default {
  props: {
    type: {
      type: String,
      default: null,
    },
    src: {
      type: String,
      default: null,
    },
    alt: {
      type: String,
      default: '',
    },
    loading: {
      type: String,
      default: 'lazy',
    },
  },
  computed: {
    typedSrc() {
      return this.$getTypedImage(this.src, this.type)
    },
    srcset() {
      if (!this.typedSrc) {
        return ''
      }
      return this.$getSrcSet(this.typedSrc)
    },
  },
}
