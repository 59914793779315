export default async function(context) {
  if (context.route.path === '/admin/'
    && context.route.query.entity === 'Article'
    && context.route.query.id
  ) {
    const article = await context.$articleRepository.getById(context.route.query.id)

    console.log('old-admin-redirect!')

    context.redirect({
      name: 'article_edit',
      params: {
        slug: article.slug,
      },
    })
  }
}
