import SuperTagOptionsBuilder from '~/helpers/SuperTagOptionsBuilder'
import FlagOptionsBuilder from '~/helpers/FlagOptionsBuilder'
import ArticleUrlGenerator from '~/helpers/ArticleUrlGenerator'
import LockResponseChecker from '~/helpers/LockResponseChecker'
import Sites from '~/helpers/Sites'
import UploadHelper from '~/helpers/UploadHelper'
import StringHelper from '~/helpers/StringHelper'
import ArticleHelper from '~/helpers/ArticleHelper'

export default (ctx, inject) => {
  const superTagOptionsBuilder = SuperTagOptionsBuilder(ctx.$superTagRepository)
  const flagOptionsBuilder = FlagOptionsBuilder(ctx.$flagRepository)
  const articleUrlGenerator = ArticleUrlGenerator(ctx.$moment)
  const lockResponseChecker = LockResponseChecker(ctx.$sentry)
  const uploadHelper = UploadHelper(ctx.$sentry, ctx.$toast)
  const sites = Sites(ctx)
  const stringHelper = StringHelper(sites, ctx.store)
  const articleHelper = ArticleHelper()

  inject('superTagOptionsBuilder', superTagOptionsBuilder)
  inject('flagOptionsBuilder', flagOptionsBuilder)
  inject('articleUrlGenerator', articleUrlGenerator)
  inject('lockResponseChecker', lockResponseChecker)
  inject('sites', sites)
  inject('uploadHelper', uploadHelper)
  inject('stringHelper', stringHelper)
  inject('articleHelper', articleHelper)
}
