
export default {
  props: {
    options: {
      type: Array,
      default() { return [] },
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    selectedOption: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Select',
    },
  },
  data() {
    return {
      content: this.value,
    }
  },
  computed: {
    state() {
      return this.errors !== null ? false : null
    },
  },
  methods: {
    handleInput(newOption) {
      // console.log('newOption', newOption)
      this.selectedOption = newOption
      this.$emit('input', newOption)
    },
  },
}
