export default (sentry, toast) => ({
  handleUploadError(error) {
    let extraMessage = ''
    if (error?.response?.status === 413) {
      extraMessage = 'Túl nagy méret, méretezd át kisebbre!'
    }
    toast.error('Sikertelen képfeltöltés! ' + extraMessage, { duration: extraMessage === '' ? 4000 : 6500 })
    console.log('Sikertelen képfeltöltés!', error)
    if (error?.response?.status !== 413 && error?.message !== 'Operation canceled by the user.') {
      sentry.captureException(new Error('Sikertelen képfeltöltés!'))
    }
  },

  /**
   * Elvileg az axios .catch() meg kéne fogjon mindent, ezért ez nem kéne hogy ez lefusson,
   * ideiglenes próbának van csak bent
   */
  handleUploadNotSuccess(response) {
    toast.error('Sikertelen képfeltöltés!', { duration: 4000 })
    console.log('Sikertelen képfeltöltés!', response)
    sentry.captureException(new Error('Sikertelen képfeltöltés, és az axios .catch() nem fogta meg!'))
  },
})
