export default $axios => ({

  async collectNamedEntities(postData) {
    return await $axios.$post('/api/speech/collect-named-entities', postData)
  },

  async synthesize(postData) {
    return await $axios.$post('/api/speech/synthesize', postData, { responseType: 'blob' })
  },

  async generateSSML(postData) {
    return await $axios.$post('/api/speech/generate-ssml', postData)
  },

  async generateMP3(postData) {
    return await $axios.$post('/api/speech/generate-mp3', postData)
  },

})
