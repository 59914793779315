
import { mapGetters } from 'vuex'
import SecondFactorLogin from '@/components/Login/google-authenticator/SecondFactorLogin'
import ResetPassword from '@/components/Login/reset-password/ResetPassword'
import CircularProgress from '@/components/Shared/CircularProgress'

export default {
  components: { CircularProgress, ResetPassword, SecondFactorLogin },
  computed: {
    ...mapGetters({
      loggedInUser: 'auth/getLoggedInUser',
    }),
  },
  watch: {
    loggedInUser(newValue, oldValue) {
      if (!newValue) {
        this.showMe = true
        return
      }
      if (!oldValue && newValue) {
        setTimeout(() => { this.showMe = false }, 1000)
      }
    },
  },
  fetch() {
    if (this.$route.query?.code) {
      this.isGoogleCallback = true
    }
    this.showMe = !this.loggedInUser
  },
  data() {
    return {
      login: {
        email: null,
        password: null,
      },
      showMe: true,
      tokenForTwoFactorVerification: null,
      isGoogleCallback: false,
      showLoginError: false,
      twoFactorStep: 1,
      showPasswordReset: false,
    }
  },
  async mounted() {
    if (this.$route.query?.code) {
      const isSuccess = await this.$googleLogin.handleGoogleResponse(this.$route.query.code)
      if (!isSuccess) {
        this.isGoogleCallback = false
        this.showLoginError = true
        return
      }

      this.showLoginError = false
      this.$emit('loginSuccess')
      setTimeout(() => { this.isGoogleCallback = false }, 1000)
      return
    }
    this.showPasswordReset = this.$route.query
      && 'stage' in this.$route.query
      && this.$route.query.stage === 'password-reset'
      && 'token' in this.$route.query
      && this.$route.query.token.length === 50
  },
  methods: {
    async onFirstFactorSubmit() {
      try {
        const response = await this.$securityRepository.firstFactor(this.login.email, this.login.password)
        // Ha nincs a 2fa validálás a backenden bekapcsolva, vagy ha már valamiért otp-verifield a tokenünk
        if (response['otp-verified'] !== false || response['otp-verified'] === true) {
          await this.$auth.handleTokenAccess(response.token, response.refreshToken)
          return
        }

        this.tokenForTwoFactorVerification = response.token
        this.twoFactorStep = 2
        this.showLoginError = false
      } catch (err) {
        this.showLoginError = true
      }
    },
    loginWithGoogle() {
      this.$googleLogin.login()
    },
    hideError() {
      this.showLoginError = false
    },
  },
}
