const lockStates = {
  FREE: 0,
  LOCKED: 1,
  ACQUIRABLE: 2,
  RELEASED: 3,
}

function getLockStateNameById(id) {
  let returnName = 'Ismeretlen state'
  Object.keys(lockStates).forEach((stateName) => {
    if (lockStates[stateName] === id) {
      returnName = stateName
    }
  })
  return returnName
}

export { lockStates, getLockStateNameById }
