import DeepDiff from 'deep-diff'

export default $sentry => ({
  checkEntity(sent, response) {
    console.log('LockSync: LockResponseChecker/checkEntity')
    if (!sent || !sent.data || !response || !response.entity || !response.entity.data) {
      console.error(
        'LockSync: Ajajj, nem sikerül a Lock Sync? v3',
        sent,
        response,
      )
      $sentry.captureException(new Error('Ajajj, nem sikerül a Lock Sync? v3/A', {
        cause: {
          newValue: sent,
          response,
        },
      }))

      return
    }

    const syncDiff = DeepDiff.diff(sent.data, response.entity.data)
    if (syncDiff) {
      console.error('Ajajj, nem sikerül a Lock Sync? v3', JSON.stringify(syncDiff), sent.data, response.entity.data)
      $sentry.captureException(new Error('Ajajj, nem sikerül a Lock Sync? v3/B', {
        cause: {
          syncDiff: JSON.stringify(syncDiff),
        },
      }))
    }
  },
})
