export default $axios => ({
  async getScheduleItems(from, to, inactive = false, user = null, scheduleTypes = []) {
    return await $axios.$get('/api/schedule-items', {
      params: {
        from,
        to,
        inactive,
        user,
        scheduleTypes,
      },
    })
  },

  async post(postData) {
    return await $axios.$post('/api/schedule-items', postData)
  },

  async delete(id) {
    return await $axios.$delete('/api/schedule-items', { data: { id } })
  },

  async activateScheduleItems(from, to) {
    return await $axios.$post('/api/schedule-items/activate', { from, to })
  },
})
