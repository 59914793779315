export default $axios => ({

  getList(page = 1, perPage = 20, orderBy = 'insertedAt', orderDesc = false, filterQuery = {}) {
    return $axios.$get('/api/screenshots?filters[hasArticle]=0', {
      params: {
        page,
        perPage,
        orderBy,
        orderType: orderDesc === false ? 'ASC' : 'DESC',
        ...filterQuery,
      },
    })
  },

  getByArticleId(articleId) {
    return $axios.$get('/api/screenshots?filters[article]=' + articleId + '&all=1')
  },

  post(postData) {
    return $axios.$post('/api/screenshots', postData)
  },

})
