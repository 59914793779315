export default $axios => ({
  getList(page = 1, perPage = 20, orderByField = 'id', orderDesc = false, filterQuery = {}) {
    const orderBy = {}
    orderBy[orderByField] = orderDesc ? 'DESC' : 'ASC'
    return $axios.$get('/api/episodes', {
      params: {
        page,
        perPage,
        orderBy,
        ...filterQuery,
      },
    })
  },

  getAll() {
    return $axios.$get('/api/episodes', {
      params: {
        all: true,
      },
    })
  },

  getBySlug(slug) {
    return $axios.$get('/api/episodes/' + slug)
  },

  async post(postData) {
    return await $axios.$post('/api/episodes', postData)
  },

  getTypeahead(typed, limit = 5) {
    return $axios.$get('/api/search/episodes?limit=' + limit + '&term=' + typed, { progress: false })
  },
})
