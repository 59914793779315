export default $axios => ({

  async post(postData) {
    return await $axios.$post('/api/livescore/event', postData)
  },

  getSports() {
    return $axios.$get('/api/livescore')
  },

  getCountries(sport) {
    return $axios.$get('/api/livescore/' + sport + '/countries')
  },

  getLeagues(sport) {
    return $axios.$get('/api/livescore/' + sport + '/leagues')
  },

  getLeaguesByCountry(sport, country) {
    return $axios.$get('/api/livescore/' + sport + '/leagues/' + country)
  },

  getSeasons(sport) {
    return $axios.$get('/api/livescore/' + sport + '/seasons')
  },

  getGroups(sport, league, season) {
    return $axios.$get('/api/livescore/' + sport + '/groups/' + league + '/' + season)
  },

  getFixturesByDay(sport, league, season, date) {
    return $axios.$get('/api/livescore/' + sport + '/fixtures/' + league + '/' + season + '/' + date)
  },

  getFixtureById(sport, fixture) {
    return $axios.$get('/api/livescore/' + sport + '/fixtures/' + fixture)
  },

  getRaces(sport, season) {
    return $axios.$get('/api/livescore/' + sport + '/races/' + season)
  },

  getGamesByDay(sport, league, season, date) {
    return $axios.$get('/api/livescore/' + sport + '/games/' + league + '/' + season + '/' + date)
  },

})
