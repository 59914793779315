export default function({ app, $axios, store, redirect }) {
  $axios.onRequest((config) => {
    if (!store.state.auth.accessToken) {
      return
    }
    if (config?.headers?.common) {
      config.headers.common.Authorization = 'Bearer ' + store.state.auth.accessToken
    }
  })

  $axios.onResponseError(async(error) => {
    console.log('onResponseError', error)
    const code = parseInt(error.response && error.response.status)
    const originalRequest = error.config
    if (originalRequest.url === '/api/token/refresh' && code === 401) {
      console.log('onResponseError: unsuccessful token refresh; invalid refresh token')
      app.$auth.logout()
      app.router.push('/login')
      return
    }
    if (code === 401 && error.response?.data?.error === 'Expired JWT Token') {
      console.log('onResponseError: try to refresh tokens')
      originalRequest.__isRetryRequest = true
      const refreshToken = store.getters['auth/getRefreshToken']

      try {
        const response = await app.$securityRepository.refreshToken(refreshToken)
        console.log('onResponseError: new tokens have arrived')
        app.$auth.handleTokenAccess(response.token, response.refreshToken)

        originalRequest.headers.Authorization = `Bearer ${response.token}`
        return $axios(originalRequest)
      } catch (e) {
        console.log('onResponseError: unsuccessful token refresh')
        app.$auth.logout()
        app.router.push('/login')
      }
    }
    return Promise.reject(error)
  })
}
