export default $axios => ({
  getList(page = 1, perPage = 20, orderBy = 'name', orderDesc = false, filterQuery = {}) {
    return $axios.$get('/api/listing-boxes', {
      params: {
        page,
        perPage,
        orderBy,
        orderType: orderDesc === false ? 'ASC' : 'DESC',
        ...filterQuery,
      },
    })
  },
  getBySlug(slug) {
    return $axios.$get('/api/listing-boxes/' + slug)
  },
  async post(postData) {
    return await $axios.$post('/api/listing-boxes', postData)
  },
})
