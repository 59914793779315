export default $axios => ({
  getList(page = 1, perPage = 20, orderBy = 'name', orderDesc = false, filterQuery = {}) {
    return $axios.$get('/api/authors', {
      params: {
        page,
        perPage,
        orderBy,
        orderType: orderDesc === false ? 'ASC' : 'DESC',
        ...filterQuery,
      },
    })
  },

  getBySlug(slug) {
    return $axios.$get('/api/authors/' + slug)
  },

  async post(postData) {
    return await $axios.$post('/api/authors', postData)
  },

  getAuthorByAuthenticatedUser() {
    return $axios.$get('/api/author')
  },

  getTypeahead(typed, limit = 5) {
    return $axios.$get('/api/search/authors?limit=' + limit + '&term=' + typed, { progress: false })
  },
})
