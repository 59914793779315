export default $axios => ({
  getList(page = 1, perPage = 20, orderBy = 'name', orderDesc = false, filterQuery = {}) {
    return $axios.$get('/api/hr/departments', {
      params: {
        page,
        perPage,
        orderBy,
        orderType: orderDesc === false ? 'ASC' : 'DESC',
        ...filterQuery,
      },
    })
  },
  getBySlug(slug) {
    return $axios.$get('/api/hr/department/' + slug)
  },
  async post(postData) {
    return await $axios.$post('/api/hr/department', postData)
  },
  getAll(orderBy = 'name', orderDesc = false, filterQuery = {}) {
    return $axios.$get('/api/hr/departments', {
      params: {
        all: 1,
        orderBy,
        orderType: orderDesc === false ? 'ASC' : 'DESC',
        ...filterQuery,
      },
    })
  },
  async delete(id) {
    return await $axios.$delete('/api/hr/department', { data: { id } })
  },
})
