
const VISITOR_REFRESH_INTERVAL = 30000
export default {
  data() {
    return {
      timeoutId: null,
    }
  },
  computed: {
    showStat() {
      return this.$store.getters.getSettings?.showStat
    },
    total() {
      return this.$store.getters.getStatData?.total
    },
    homepage() {
      return this.$store.getters.getStatData?.homepage
    },
    currentPath() {
      return this.$nuxt.$route.path
    },
    numberOfBoxes() {
      return this.$store.getters['frontpage/getBlocks']
        .filter(block => block.active)
        .reduce((sum, block) => {
          return sum + block.groups.reduce((sum2, group) => sum2 + group.boxes.length, 0)
        }, 0)
    },
  },
  mounted() {
    this.refresh()
  },
  beforeDestroy() {
    if (this.timeoutId !== null) {
      clearTimeout(this.timeoutId)
    }
  },
  methods: {
    async refresh() {
      try {
        const response = await this.$beamRepository.getStatData()
        this.$store.commit('setStatData', response)
        this.$store.commit('setStatDataCounter', 0)
      } catch (e) {
        console.error(e)
        this.$store.commit('setStatDataCounter', 1 + this.$store.getters.getStatDataCounter)
        if (this.$store.getters.getStatDataCounter > 3) {
          this.$sentry.captureException(new Error('Nem működik a statisztika API.'))
        }
      } finally {
        this.timeoutId = setTimeout(this.refresh, VISITOR_REFRESH_INTERVAL)
      }
    },
  },
}
