/**
 * Az éppen nyitott oldal lock state-jét kezelő store
 */
import { lockStates } from '~/helpers/lockStates'

let socketDisconnectedTimer = null

export const state = () => ({
  page: null,
  isInited: false,
  lockData: null,
  lastSyncTime: 0,
  syncFailedReloadNeed: false,
  socketConnected: false,
  pendingLock: null,
  eventCount: 0,
})

export const mutations = {
  setPage(state, page) {
    state.page = page
  },

  syncFailedReloadNeed(state, isFailed) {
    state.syncFailedReloadNeed = isFailed
  },

  setSocketConnected(state, socketConnected) {
    state.socketConnected = socketConnected
  },

  setLockData(state, lockData) {
    state.lockData = lockData
  },

  setInited(state, isInited) {
    state.isInited = isInited
  },

  bumpLastSyncTime(state) {
    state.lastSyncTime = Date.now()
  },

  setPendingLock(state, pendignLock) {
    state.pendingLock = pendignLock
  },

  resetState(state) {
    state.page = null
    state.isInited = false
    state.lockData = null
    state.cooldown = false
    state.pendingLock = null
  },

  incrementEventCount(state) {
    state.eventCount++
  },
}

export const actions = {
  async initOnNewPage({ commit, state, getters, dispatch }, payload) {
    console.log('store/pageLock, initOnNewPage run')
    // Set current page
    commit('setPage', payload.page)

    // Set pageLock to NOT INITED
    commit('setInited', false)

    // Get the current lock
    const response = await window.$nuxt.$lockManager.getLockByPage(payload.page)
    commit('setLockData', response)
    // Ha autoLockkal initializálunk, és nincsen senki által lockolva, vagy a mienk egy masik tabon
    if ((payload.autoLock && !getters.currentLockOwner)
      || (response && response.user && getters.me.id === response.user.id)
    ) {
      if (response && response.entity) {
        payload.entity = response.entity
      }
      await dispatch('setLock', payload)
    }

    commit('setInited', true)
  },

  async setLock({ commit, state }, payloadParam) {
    if (state.lastSyncTime + 100 < Date.now()) {
      commit('incrementEventCount')
      commit('bumpLastSyncTime')
      commit('setPendingLock', null)
      setTimeout(async() => {
        if (state.pendingLock) {
          commit('incrementEventCount')
          console.log('LockSync: belefutott a pendingLock-ba', state.pendingLock)
          const pendingLock = JSON.parse(JSON.stringify(state.pendingLock))
          const response = await window.$nuxt.$lockManager.setLockOnPage(...pendingLock)
          window.$nuxt.$lockResponseChecker.checkEntity(pendingLock[1], response)
          commit('setPendingLock', null)
          commit('setLockData', response)
        }
      }, 100)

      const payload = JSON.parse(JSON.stringify(payloadParam))
      const response = await window.$nuxt.$lockManager.setLockOnPage(state.page, payload.entity, payload.acquire)
      window.$nuxt.$lockResponseChecker.checkEntity(payload.entity, response)
      commit('setLockData', response)

      return response
    } else {
      commit('setPendingLock', [state.page, payloadParam.entity, payloadParam.acquire])
    }
  },

  async releaseLock({ commit, state }, tabOnly) {
    const response = await window.$nuxt.$lockManager.releaseLockOnPage(state.page, tabOnly)
    console.log('releaseThisPagesLock response: ', response)
    commit('setLockData', null)
  },

  async userActivity({ commit, state, getters, dispatch }, payload) {
    if (getters.isItMyLock) {
      console.log('userActivity')
      const response = await dispatch('setLock', {
        entity: payload.entity,
        acquire: false,
      })
      /* TODO: vissza kell kapcsolni, de előtte ellenőrizni kell, hogy címlap mentésnél miért jön elő
       * commit('syncFailedReloadNeed', response === undefined)
       */
      return response
    }
  },

  setSocketDisconnected({ commit, state }) {
    console.log('setSocketDisconnected run')
    commit('setSocketConnected', false)
    const TIMEOUT_MS = 30000
    socketDisconnectedTimer = setTimeout(() => {
      console.log(TIMEOUT_MS + 'ms óta nincs pageLock socket!')
      if (!state.socketConnected) {
        commit('syncFailedReloadNeed', true)
      }
    }, TIMEOUT_MS)
  },

  setSocketConnected({ commit }) {
    console.log('setSocketConnected run')
    commit('setSocketConnected', true)
    commit('syncFailedReloadNeed', false)
    if (socketDisconnectedTimer) {
      console.log('stop/clear socketDisconnectedTimer')
      clearTimeout(socketDisconnectedTimer)
    }
  },
}

export const getters = {
  lockData(state) {
    return state.lockData
  },
  isInited(state) {
    return state.isInited
  },
  me(state, getters, rootState, rootGetters) {
    return rootGetters['auth/getLoggedInUser']
  },
  // Miénk-e a lock
  isItMyLock: (state, getters, rootState, rootGetters) => {
    if (!state.lockData || !state.lockData.user || state.lockData.state === lockStates.RELEASED) {
      return false
    }
    return rootGetters['auth/getLoggedInUser'].id === state.lockData.user.id
  },
  // Átvehető-e state-ben van-e nekem a lock
  isAcquirable: (state, getters) => {
    return !getters.isItMyLock
      && state.lockData !== null
      && (state.lockData.state === lockStates.ACQUIRABLE || state.lockData.state === lockStates.RELEASED)
  },
  // Lehet-e lockot csinálni épp:
  isLockable: (state, getters) => {
    if (!state.isInited) {
      return false
    }

    return !getters.currentLockOwner || getters.isAcquirable
  },
  // A jelenlegi lock tulajdonosa
  currentLockOwner: (state) => {
    return state.lockData ? state.lockData.user : null
  },
  lockingDisabled: (state, getters) => {
    return !getters.isLockable
  },
  isSocketInited(state, getters, rootState, rootGetters) {
    return rootGetters['locks/isSocketInited']
  },
  isSyncFailedReloadNeed(state) {
    return state.syncFailedReloadNeed
  },
}
