export default $axios => ({

  uploadImage(base64, config, assetTypeName = 'cimlap-nagy') {
    const params = {
      image: base64,
      assetTypeName,
    }

    return $axios.$post('/api/images', params, config)
  },

  uploadCroppedImage(params, config) {
    return $axios.$post('/api/images', params, config)
  },

  uploadFile(base64) {
    return $axios.$post('/api/uploads', {
      file: base64,
    })
  },
})
