export class RootManager {
  constructor(ctx, tabId, clientVersion) {
    this.ctx = ctx
    this.tabId = tabId
    this.store = ctx.$store
    this.clientVersion = clientVersion
  }

  async initSocket() {
    this.socket = await this.ctx.$nuxtSocket({
      name: 'default',
      persist: 'rootSocket',
      channel: '/',
      reconnection: true,
      teardown: false,
      forceNew: false,
      auth: {
        token: this.store.getters['auth/getAccessToken'],
        tabId: this.tabId,
        clientVersion: this.clientVersion,
      },
    })

    this.socket.on('init', () => {
      this.store.commit('socketInited', true)
      this.store.dispatch('syncCurrentRoute', this.store.state.currentRoute)
    })

    this.socket.on('version', (serverVersion) => {
      console.log('client version: ', this.clientVersion)
      console.log('server version: ', serverVersion)

      if (this.clientVersion && this.clientVersion.toString() !== serverVersion.toString()) {
        this.store.dispatch('forceReload')
      }
    })

    // Valaki kért tőlem egy stateDump-ot
    this.socket.on('requestUserState', (sendToTabId) => {
      console.log('requestUserState!, requested by:', sendToTabId)
      const state = JSON.parse(JSON.stringify(this.store.state))
      delete state.locks
      state.clientVersion = this.clientVersion
      state.cookies = this.ctx.$cookies.getAll()
      state.localStorage = window.localStorage
      this.emit('sendStateDump', { sendToTabId, state })
    })

    // Valaki küld nekem egy userState-et
    this.socket.on('stateDump', (payload) => {
      console.log('stateDump arrived!!!', payload)
      this.store.commit('setRequestedState', payload.state)
    })

    // Ha változott a userem (valaki szerkesztette az adminban)
    this.socket.on('userChanged', () => {
      this.ctx.$auth.setMe()
    })

    // Ha változott a kapcsolódott userek listája (csak akkor kapom ha a remote_debug oldalon vagyok)
    this.socket.on('connectedUsersChanged', (users) => {
      this.store.commit('remoteDebugList/setConnectedUsers', users)
    })

    // Ha változott a broadcast üzenet
    this.socket.on('broadcastChanged', (payload) => {
      this.store.commit('setBroadcast', payload)
    })

    // Ha változott a message
    this.socket.on('messageChanged', (payload) => {
      this.store.commit('setMessages', payload)
    })
  }

  async emit(event, message = null) {
    if (!this.socket) {
      console.log('socket is not inited yet! request dropped;')
      return
    }

    return await this.store.dispatch(
      '$nuxtSocket/emit',
      {
        socket: this.socket,
        evt: event,
        msg: message,
      },
    )
  }

  async getUsers() {
    const users = await this.emit('getUsers')
    return users
  }

  async requestUserState(tabId) {
    const requestResponse = await this.emit('requestUserState', { tabId })
    return requestResponse
  }

  async userChanged(payload) {
    console.log('RootManager: userChanged called')
    return await this.emit('userChanged', payload)
  }

  // Frissítem az éppen aktuális route-om a socket szerverre
  async syncCurrentRoute(route) {
    return await this.emit('syncCurrentRoute', route)
  }

  async setBroadcast(payload) {
    return await this.emit('setBroadcast', payload)
  }

  async addMessage(payload) {
    return await this.emit('addMessage', payload)
  }

  async updateMessage(payload) {
    return await this.emit('updateMessage', payload)
  }

  async deleteMessage(payload) {
    return await this.emit('deleteMessage', payload)
  }

  closeSocket() {
    if (this.socket) {
      this.socket.disconnect()
    }
  }
}
