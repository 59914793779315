export default (question) => {
  const errors = []
  if (!question.answers || question.answers.length === 0) {
    errors.push('A kérdéshez meg kell adni válaszokat!')
  }

  if (!question.rightAnswer?.value) {
    errors.push('Meg kell jelölni egy választ helyesnek!')
  }

  return errors.length > 0 ? { valid: false, errors } : { valid: true, errors: null }
}
