
export default {
  props: {
    token: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showLoginError: null,
      code: '',
      hideCode: true,
    }
  },
  mounted() {
    this.$nextTick(() => this.$refs.secondFactorInput.focus())
  },
  methods: {
    async onSubmit() {
      try {
        const response = await this.$securityRepository.secondFactor(this.token, this.code)
        await this.$auth.handleTokenAccess(response.token, response.refreshToken)
        this.showLoginError = false
        this.$emit('loginSuccess')
        setTimeout(() => { this.$emit('hide') }, 800)
      } catch (err) {
        this.showLoginError = true
      }
    },
    back() {
      this.token = ''
      this.$emit('hide')
    },
  },
}
