export default $superTagRepository => ({
  async getOptions() {
    const superTags = await $superTagRepository.getAll()
    return superTags.map((superTag) => {
      return {
        label: superTag.name,
        value: superTag.slug,
      }
    })
  },
  async getOptionsForSearch() {
    const superTags = await $superTagRepository.getAll()
    return superTags.map((superTag) => {
      return {
        label: superTag.name,
        value: superTag.name,
      }
    })
  },
})
