export const state = () => ({
  editedDoc: null,
  isReloading: false,
  isSaving: false,
})

export const mutations = {
  updateEditedDoc(state, payload) {
    state.editedDoc = payload
  },

  updateEditedDocField(state, payload) {
    const newDocEdit = JSON.parse(JSON.stringify(state.editedDoc))
    newDocEdit[payload.field] = payload.value
    state.editedDoc = newDocEdit
  },

  updateIsReloading(state, isReloading) {
    state.isReloading = isReloading
  },

  setIsSaving(state, isSaving) {
    state.isSaving = isSaving
  },

  initNewDoc(state) {
    state.editedDoc = {
      id: null,
      title: 'Dokumentum címe',
      lead: 'Ez a lead szöveg helye',
      slug: null,
      content: 'Ez a tartalom helye',
      active: false,
      updatedAt: Math.round(new Date() / 1000),
    }

    console.log('initNewDoc run!', state.editedDoc)
  },
}

export const actions = {
  save({ commit, state, getters }) {
    console.log('docEdit; save action;')
    commit('setIsSaving', true)
    window.$nuxt.$docRepository.post({
      id: state.editedDoc.id,
      active: !!state.editedDoc.active,
      title: state.editedDoc.title,
      lead: state.editedDoc.lead,
      content: state.editedDoc.content,
      slug: state.editedDoc.slug,
      updatedAt: state.editedDoc.updatedAt,
    }).then((response) => {
      commit('setIsSaving', false)
      window.$nuxt.$bus.$emit('doc-save-success', response)
    }).catch((error) => {
      commit('setIsSaving', false)
      window.$nuxt.$bus.$emit('doc-save-error', error)
    })
  },
  /* Pl. ha nem nálunk van a lock */
  updateDocWithNotify({ commit }, doc) {
    commit('updateEditedDoc', doc)
    if (process.client && window.$nuxt && window.$nuxt.$bus) {
      window.$nuxt.$bus.$emit('doc-content-override', doc.content)
    }
  },
}
