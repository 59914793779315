export default $axios => ({
  getList(page = 1, perPage = 20, orderBy = 'insertedAt', orderDesc = false, filterQuery = {}) {
    return $axios.$get('/api/notifications', {
      params: {
        page,
        perPage,
        orderBy,
        orderType: orderDesc === false ? 'ASC' : 'DESC',
        ...filterQuery,
      },
    })
  },

  getAll() {
    return $axios.$get('/api/notifications', {
      params: {
        all: true,
      },
    })
  },

  getById(id) {
    return $axios.$get('/api/notifications/' + id)
  },

  send(id) {
    return $axios.$post('/api/notifications/' + id + '/send')
  },

  async post(postData) {
    return await $axios.$post('/api/notifications', postData)
  },
})
