export default $flagRepository => ({
  async getOptions() {
    const flags = await $flagRepository.getAll()
    return flags.map((flag) => {
      return {
        label: flag.name,
        value: flag.slug,
      }
    })
  },
  async getOptionsForSearch() {
    const flags = await $flagRepository.getAll()
    return flags.map((flag) => {
      return {
        label: flag.name,
        value: flag.name,
      }
    })
  },
})
