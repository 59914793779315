export default ($store, $cookies, $securityRepository) => ({
  async handleTokenAccess(accessToken, refreshToken) {
    this.setTokens(accessToken, refreshToken)
    await this.setMe()
  },

  setTokens(accessToken, refreshToken) {
    $store.commit('auth/setAccessToken', accessToken)
    $store.commit('auth/setRefreshToken', refreshToken)
    const now = new Date()
    const accessTokenExpire = new Date()
    accessTokenExpire.setTime(now.getTime() + 60 * 60 * 24 * 7 * 1000)

    const refreshTokenExpire = new Date()
    refreshTokenExpire.setTime(now.getTime() + 60 * 60 * 24 * 14 * 1000)

    $cookies.set(
      'accessToken',
      accessToken,
      {
        expires: accessTokenExpire,
        path: '/',
      },
    )

    $cookies.set(
      'refreshToken',
      refreshToken,
      {
        expires: refreshTokenExpire,
        path: '/',
      },
    )
  },

  async setMe() {
    const me = await $securityRepository.getMe()
    $store.commit('auth/setLoggedInUser', me)
  },

  logout() {
    console.log('logout called!')
    $store.commit('auth/clearUserData')
    $cookies.remove('accessToken')
    $cookies.remove('refreshToken')
    $cookies.remove('lastPage')
  },

  getAccessToken() {
    return $store.getters['auth/getAccessToken']
  },

  getLoggedInUser() {
    return $store.getters['auth/getLoggedInUser']
  },
})
