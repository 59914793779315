
export default {
  props: {
    picType: {
      type: String,
      default: 'normal',
    },
    pic1: {
      type: String,
      default: null,
    },
    pic2: {
      type: String,
      default: null,
    },
    pic3: {
      type: String,
      default: null,
    },
    pic4: {
      type: String,
      default: null,
    },
    align: {
      type: String,
      default: 'top',
    },
    imageAlt: {
      type: String,
      default: '',
    },
    vertical: {
      type: String,
      default: 'false',
    },
  },
  computed: {
    picTypeClass() {
      if (this.picType === 'wide') {
        return 'long-img--bigger'
      }

      if (this.picType === 'full') {
        return 'long-img--full'
      }

      return 'long-img--normal'
    },
    alignClass() {
      if (this.vertical === 'true') {
        return 'long-img--vertical'
      } else if (!this.pic1 || !this.pic2 || this.pic1 === '' || this.pic2 === '') {
        return 'long-img--solo'
      } else if (this.align === 'center') {
        return 'long-img--center'
      } else if (this.align === 'bottom') {
        return 'long-img--bottom'
      }

      return 'long-img--top'
    },
  },
  methods: {
    getSrcset(path) {
      const srcsetItems = []

      for (let i = 1; i++ < 4;) {
        srcsetItems.push(path.replace(/(.(png|jpeg|jpg|gif))$/g, '@' + i + 'x$1 ' + i + 'x'))
      }

      return srcsetItems.join(', ')
    },
    setImageIndex(thumb) {
      this.$store.dispatch('lightBox/setImageIndexByThumb', thumb)
    },
  },
}
