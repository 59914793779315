
import LottieAnimation from 'lottie-web-vue'
import * as animationData from '~/assets/lottie/animation-delete-cookie.json'

export default {
  components: { LottieAnimation },
  data() {
    return {
      lottieOptions: { animationData: animationData.default },
      anim: null,
    }
  },
  watch: {
    showAnimation(newValue) {
      if (newValue) {
        this.clearCookies()
      }
    },
  },
  computed: {
    showAnimation: {
      get() {
        return this.$store.getters['layout/isShowClearCookie']
      },
      set(newValue) {
        this.$store.commit('layout/setShowClearCookie', newValue)
      },
    },
  },
  methods: {
    clearCookies() {
      this.$refs.anim.play()
      console.log('ALL COOKIES', this.$cookies.getAll({ fromRes: false }))
      console.log('ALL COOKIES RES', this.$cookies.getAll({ fromRes: true }))
      Object.entries(this.$cookies.getAll()).forEach(([cookieKey, cookieValue]) => {
        if (!['refreshToken', 'accessToken'].includes(cookieKey)) {
          console.log('Cookie lekérdezése: ', cookieKey, this.$cookies.get(cookieKey))
          console.log('Cookie törlése: ', cookieKey)
          this.$cookies.remove(cookieKey, { domain: '.telex.hu' })
        }
      })
    },
    cookieAnimationComplete() {
      this.showAnimation = false
      this.$toast.success('Cookiek törölve!', { duration: 5000 })
      this.$refs.anim.stop()
    },
  },
}
