export const state = () => ({
  editedArticle: null,
  articleParent: null,
  saveResponse: null,
  originalData: {
    title: '',
    recommender: '',
    recommendedBoxImage: null,
    resizedRecommendedBoxImage: null,
    active: false,
  },

  // Modal toggles:
  tagsModalVisible: false,
  superTagsModalVisible: false,
  mainSuperTagsModalVisible: false,
  authorModalVisible: false,
  contributorModalVisible: false,
  votingModalVisible: false,
  coverVideoModalVisible: false,
  quickVoteModalVisible: false,
  technicalEmbedModalVisible: false,
  advancedImageModalVisible: false,
  longChapterModalVisible: false,
  coverImageModalVisible: false,
  toLiveBlogModalVisible: false,
  articleModifiedModalVisible: false,
  inactiveConfirmModalVisible: false,
  notLiveLiveBlogConfirmModalVisible: false,
  avCodeModalVisible: false,
  multipleCropModalVisible: false,
  preSelectionModalVisible: false,
  holdBlogModalVisible: false,

  // Cropper
  cropperModalVisible: false,
  cropperConfig: null,
  cropperImage: null,
  cropperOriginalImage: null,

  isReloading: false,
  isSaving: false,
  showVersions: false,
  showScreenshots: false,
})

export const mutations = {
  updateEditedArticle(state, payload) {
    state.editedArticle = payload
  },

  updateArticleParent(state, payload) {
    state.articleParent = payload
  },

  updateEditedArticleField(state, payload) {
    const newArticleEdit = JSON.parse(JSON.stringify(state.editedArticle))
    newArticleEdit[payload.field] = payload.value

    state.editedArticle = newArticleEdit
  },

  updateIsReloading(state, isReloading) {
    state.isReloading = isReloading
  },

  setTagsModalVisible(state, isVisible) {
    state.tagsModalVisible = isVisible
  },

  setSuperTagsModalVisible(state, isVisible) {
    state.superTagsModalVisible = isVisible
  },

  setMainSuperTagsModalVisible(state, isVisible) {
    state.mainSuperTagsModalVisible = isVisible
  },

  setAuthorModalVisible(state, isVisible) {
    state.authorModalVisible = isVisible
  },

  setContributorModalVisible(state, isVisible) {
    state.contributorModalVisible = isVisible
  },

  setTechnicalEmbedModalVisible(state, isVisible) {
    state.technicalEmbedModalVisible = isVisible
  },

  setVotingModalVisible(state, isVisible) {
    state.votingModalVisible = isVisible
  },

  setCoverVideoModalVisible(state, isVisible) {
    state.coverVideoModalVisible = isVisible
  },

  setQuickVoteModalVisible(state, isVisible) {
    state.quickVoteModalVisible = isVisible
  },

  setAdvancedImageModalVisible(state, isVisible) {
    state.advancedImageModalVisible = isVisible
  },

  setLongChapterModalVisible(state, isVisible) {
    state.longChapterModalVisible = isVisible
  },

  setCoverImageModalVisible(state, isVisible) {
    state.coverImageModalVisible = isVisible
  },

  setToLiveBlogModalVisible(state, isVisible) {
    state.toLiveBlogModalVisible = isVisible
  },

  setArticleModifiedModalVisible(state, isVisible) {
    state.articleModifiedModalVisible = isVisible
  },

  setCropperModalVisible(state, isVisible) {
    state.cropperModalVisible = isVisible
  },

  setInactiveConfirmModalVisible(state, isVisible) {
    state.inactiveConfirmModalVisible = isVisible
  },

  setNotLiveLiveBlogConfirmModalVisible(state, isVisible) {
    state.notLiveLiveBlogConfirmModalVisible = isVisible
  },

  setPreSelectionModalVisible(state, isVisible) {
    state.preSelectionModalVisible = isVisible
  },

  setHoldBlogModalVisible(state, isVisible) {
    state.holdBlogModalVisible = isVisible
  },

  setCropperConfig(state, cropperConfig) {
    state.cropperConfig = cropperConfig
  },

  setCropperImage(state, cropperImage) {
    state.cropperImage = cropperImage
  },

  setCropperOriginalImage(state, cropperOriginalImage) {
    state.cropperOriginalImage = cropperOriginalImage
  },

  setIsSaving(state, isSaving) {
    state.isSaving = isSaving
  },

  setShowVersions(state, showVersions) {
    state.showVersions = showVersions
  },

  setShowScreenshots(state, showScreenshots) {
    state.showScreenshots = showScreenshots
  },

  setSaveResponse(state, saveResponse) {
    state.saveResponse = saveResponse
  },

  initNewArticle(state, payload) {
    const parentArticle = payload.parentArticle
    const author = payload.author
    const type = payload.type
    const tags = payload.tags

    state.editedArticle = {
      id: null,
      title: null,
      subtitle: null,
      lead: null,
      slug: null,
      content: null,
      coverImage: null,
      coverVideo: null,
      pubDate: null,
      scheduledAt: null,
      articleAuthors: author.authorId ? [author] : [],
      articleContributors: [],
      tags: parentArticle ? parentArticle.tags : tags,
      superTags: parentArticle ? parentArticle.superTags : [],
      mainSuperTag: parentArticle ? parentArticle.mainSuperTag : null,
      flags: [],
      facebookImage: null,
      recommendedBoxImage: null,
      mobileImage43: null,
      mobileImage11: null,
      downloads: [],
      active: false,
      updatedAt: null,
      parent: parentArticle ? parentArticle.id : null,
      type,
      rank: 3,
      subType: 'N',
      headerType: null,
      minute: null,
      colorClass: null,
      avCode: null,
      speech: null,
    }

    console.log('initNewArticle run!', state.editedArticle)
  },

  setAvCodeModalVisible(state, isVisible) {
    state.avCodeModalVisible = isVisible
  },

  setMultipleCropModalVisible(state, isVisible) {
    state.multipleCropModalVisible = isVisible
  },

  setOriginalData(state, payload) {
    state.originalData = payload
  },
}

export const actions = {
  async save({
    commit,
    state,
    getters,
  }, softSave) {
    console.log('articleEdit; saveAction;')
    await window.$nuxt.$articleRepository.post({
      id: state.editedArticle.id,
      parent: state.editedArticle.parent,
      type: state.editedArticle.isLiveBlog ? 'liveblog' : state.editedArticle.type,
      active: JSON.parse(state.editedArticle.active),
      title: state.editedArticle.title,
      subtitle: state.editedArticle.subtitle,
      lead: state.editedArticle.lead,
      content: state.editedArticle.content,
      tags: state.editedArticle.tags.map(tag => (tag.id) ? { id: tag.id } : { id: null, name: tag.name }),
      superTags: state.editedArticle.superTags.map(superTag => Number(superTag.id)),
      mainSuperTag: state.editedArticle.mainSuperTag ? Number(state.editedArticle.mainSuperTag.id) : null,
      flags: state.editedArticle.flags.map(flag => Number(flag.id)),
      coverImage: state.editedArticle.coverImage,
      coverVideo: state.editedArticle.coverVideo,
      mobileImage43: state.editedArticle.mobileImage43,
      mobileImage11: state.editedArticle.mobileImage11,
      articleAuthors: state.editedArticle.articleAuthors.map((author, index) => {
        return {
          id: author.id ?? null,
          position: index,
          author: {
            id: author.authorId,
            name: author.name,
          },
        }
      }),
      articleContributors: state.editedArticle.articleContributors.map(author => (author.id) ? { id: author.id } : { id: null, name: author.name }),
      recommender: state.editedArticle.recommender,
      facebookImage: state.editedArticle.facebookImage,
      recommendedBoxImage: state.editedArticle.recommendedBoxImage,
      coverImageText: state.editedArticle.coverImageText,
      slug: state.editedArticle.slug,
      rank: state.editedArticle.rank,
      subType: state.editedArticle.subType,
      headerType: state.editedArticle.headerType,
      minute: state.editedArticle.minute,
      avCode: state.editedArticle.avCode !== '' ? state.editedArticle.avCode : null,
      spellCheck: null,
      pubDate: state.editedArticle.pubDate && state.editedArticle.pubDate > 0 ? parseInt(state.editedArticle.pubDate) : null,
      scheduledAt: state.editedArticle.scheduledAt,
      colorClass: state.editedArticle.colorClass,
      contextTitle: state.editedArticle.contextTitle,
      contextLead: state.editedArticle.contextLead,
      speech: state.editedArticle.speech,
      pronounces: state.editedArticle.pronounces,
      livescoreId: state.editedArticle.livescoreId,
    }, softSave).then((response) => {
      commit('setIsSaving', false)
      window.$nuxt.$bus.$emit('article-save-success', response)
    }).catch((error) => {
      commit('setIsSaving', false)
      window.$nuxt.$bus.$emit('article-save-error', { type: 'articleSaveError', error })
    })
  },
  updateOriginalData({ commit }, article) {
    commit('setOriginalData', {
      title: article.title,
      recommender: article.recommender,
      recommendedBoxImage: article.recommendedBoxImage,
      resizedRecommendedBoxImage: article.resizedRecommendedBoxImage,
      active: article.active,
    })
  },
  /* Pl. ha nem nálunk van a lock */
  updateArticleWithNotify({ commit }, article) {
    commit('updateEditedArticle', article)
    if (process.client && window.$nuxt && window.$nuxt.$bus) {
      window.$nuxt.$bus.$emit('article-content-override', article.content)
    }
  },
}

export const getters = {
  isFrontpageDataChanged(state) {
    return state.editedArticle.title !== state.originalData.title
      || state.editedArticle.recommender !== state.originalData.recommender
      || state.editedArticle.recommendedBoxImage !== state.originalData.recommendedBoxImage
  },
  wasActiveAtOpen(state) {
    return state.originalData.active
  },
}
