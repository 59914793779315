
export default {
  props: {
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: null,
    },
    label: {
      type: String,
      default: 'Input',
    },
  },
  data() {
    return {
      checked: this.value,
    }
  },
  computed: {
    state() {
      return this.errors !== null ? false : null
    },
  },
  watch: {
    value(newValue) {
      this.checked = newValue
    },
  },
  methods: {
    change() {
      this.checked = !this.checked
      this.$emit('input', this.checked)
    },
  },
}
