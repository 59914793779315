export const state = () => ({
  counter: 0,
  currentRoute: null,
  reloadNeeded: false,
  statData: null,
  statDataCounter: 0,
  adBlocked: false,
  logs: [],
  requestedState: null,
  socketInited: false,
  settings: {
    showStat: true,
    dayView: false,
    wideCalendar: false,
    splitDays: [],
  },
  broadcast: null,
  messages: [],
  tabId: null,
})

export const mutations = {
  increment(state) {
    state.counter++
  },
  setReloadNeeded(state, reloadNeded) {
    state.reloadNeeded = reloadNeded
  },
  setSettings(state, payLoad) {
    state.settings = payLoad
  },
  setSettingsField(state, payLoad) {
    state.settings[payLoad.field] = payLoad.value
  },
  setStatData(state, statData) {
    state.statData = statData
  },
  setStatDataCounter(state, counter) {
    state.statDataCounter = counter
  },
  setAdBlocked(state, isBlocked) {
    state.adBlocked = isBlocked
  },
  setCurrentRoute(state, route) {
    state.currentRoute = route
  },
  socketInited(state, socketInited) {
    state.socketInited = socketInited
  },
  resetLogs(state) {
    state.logs = []
  },
  addLog(state, log) {
    state.logs.push(log)
  },
  setRequestedState(state, requestedState) {
    state.requestedState = requestedState
  },
  setBroadcast(state, payLoad) {
    state.broadcast = payLoad
  },
  setMessages(state, payLoad) {
    state.messages = payLoad
  },
  addMessage(state, payLoad) {
    state.messages.push(payLoad)
  },
  generateTabId(state) {
    state.tabId = (Math.random().toString(36) + '00000000000000000').slice(2, 12)
    console.log('tabId, generator', state.tabId)
  },
  updateMessage(state, payLoad) {
    const currentMessage = state.messages.filter(m => m.id === payLoad.id)[0]
    currentMessage.message = payLoad.message
    currentMessage.important = payLoad.important
  },
  deleteMessage(state, payLoad) {
    for (let i = 0; i < state.messages.length; i++) {
      if (state.messages[i].id === payLoad.id) {
        state.messages.splice(i, 1)
      }
    }
  },
}

export const actions = {
  async nuxtServerInit({ commit }, {
    $cookies,
    $auth,
  }) {
    commit('generateTabId')

    // Régi tokenek kezelése az átálláshoz
    const oldAccessToken = $cookies.get('auth._token.local')
    const oldRefreshToken = $cookies.get('auth._refresh_token.local')

    if (oldAccessToken && oldRefreshToken) {
      await $auth.handleTokenAccess(oldAccessToken.replace('Bearer ', ''), oldRefreshToken)
      $cookies.remove('auth._token.local')
      $cookies.remove('auth._refresh_token.local')
      $cookies.remove('auth._refresh_token_expiration.local')
      $cookies.remove('auth._token_expiration.local')
    }

    const accessToken = $cookies.get('accessToken')
    const refreshToken = $cookies.get('refreshToken')
    if (accessToken && refreshToken) {
      await $auth.handleTokenAccess(accessToken, refreshToken)
    }
  },

  forceReload({ commit }) {
    commit('setReloadNeeded', true)
  },
  clearReload({ commit }) {
    commit('setReloadNeeded', false)
  },
  async initRootSocket({ commit }) {
    console.log('init rootSocket')
    await window.$nuxt.$rootManager.initSocket()
  },
  async closeRootSocket({ commit }) {
    console.log('close rootSocket')
    await window.$nuxt.$rootManager.closeSocket()
    commit('socketInited', false)
  },
  // Ha egy usert szerkesztettünk
  async userChanged({ commit }, userId) {
    console.log('userChanged called')
    await window.$nuxt.$rootManager.userChanged({ userId })
  },
  async syncCurrentRoute({ commit }, route) {
    const routeToCommit = {
      name: route.name,
      fullPath: route.fullPath,
      params: route.params,
    }

    console.log('syncCurrentRoute action called', routeToCommit)
    return await window.$nuxt.$rootManager.syncCurrentRoute(routeToCommit)
  },
  refreshMe({ commit }) {
    console.log('refreshMe run')
    window.$nuxt.$auth.setMe()
  },
  async setBroadcast({ commit }, payLoad) {
    commit('setBroadcast', payLoad)
    return await window.$nuxt.$rootManager.setBroadcast(payLoad)
  },
  async addMessage({ commit }, payLoad) {
    return await window.$nuxt.$rootManager.addMessage(payLoad)
  },
  async updateMessage({ commit }, payLoad) {
    return await window.$nuxt.$rootManager.updateMessage(payLoad)
  },
  async deleteMessage({ commit }, payLoad) {
    return await window.$nuxt.$rootManager.deleteMessage(payLoad)
  },
}

export const getters = {
  isAuthenticated(state) {
    if (!state.auth) {
      // console.log('nincs state.auth!')
      return false
    }
    return state.auth.loggedIn
  },
  isSocketInited(state) {
    return state.socketInited
  },
  isReloadNeeded(state) {
    return state.reloadNeeded
  },
  getSettings(state) {
    return state.settings
  },
  getStatData(state) {
    return state.statData
  },
  getStatDataCounter(state) {
    return state.statDataCounter
  },
  getRequestedState(state) {
    return state.requestedState
  },
  getBroadcast(state) {
    return state.broadcast
  },
  getMessages(state) {
    return state.messages
  },
}
