import Vue from 'vue'

export const state = () => ({
  socketInited: false,
  blocks: [],
  lockBlocks: [],
  layouts: [],
  boxEditor: {
    isShow: false,
    blockIndex: null,
    groupIndex: null,
    boxIndex: null,
    isEdit: false,
    editedBox: null,
    groupMeta: null,
    status: 'no-content',
    warnings: [],
    errors: [],
  },
  notifications: [],
  showNotifications: false,
  activeItemConfigId: null,
  isPreviewMode: false,
  scrollInMobilView: null,
  scrollInDesktopView: null,
  isDndInProgress: 0,
  superTagsWithLogo: [],
  customTags: [],
})

export const mutations = {
  socketInited(state, socketInited) {
    state.socketInited = socketInited
  },
  updateBlocks(state, payload) {
    state.blocks = JSON.parse(JSON.stringify(payload))
    state.lockBlocks = JSON.parse(JSON.stringify(payload))
  },
  updateBlock(state, payload) {
    Vue.set(state.blocks, payload.index, payload.block)
    state.lockBlocks = JSON.parse(JSON.stringify(state.blocks))
  },
  updateBlockField(state, payload) {
    Vue.set(state.blocks[payload.blockIndex], payload.field, payload.value)
    state.lockBlocks = JSON.parse(JSON.stringify(state.blocks))
  },
  updateGroupField(state, payload) {
    Vue.set(state.blocks[payload.blockIndex].groups[payload.groupIndex], payload.field, payload.value)
    state.lockBlocks = JSON.parse(JSON.stringify(state.blocks))
  },
  updateBoxField(state, payload) {
    Vue.set(state.blocks[payload.blockIndex].groups[payload.groupIndex].boxes[payload.boxIndex], payload.field, payload.value)
    state.lockBlocks = JSON.parse(JSON.stringify(state.blocks))
  },
  updateLayouts(state, payload) {
    state.layouts = payload
  },
  updateBoxEditor(state, payload) {
    state.boxEditor = payload
  },
  updateBoxEditorField(state, payload) {
    Vue.set(state.boxEditor, payload.field, payload.value)
  },
  updateEditedBox(state, payload) {
    Vue.set(state.boxEditor, 'editedBox', payload)
  },
  updateEditedBoxField(state, payload) {
    Vue.set(state.boxEditor.editedBox, payload.field, payload.value)
  },
  updateActiveItemConfigId(state, payload) {
    state.activeItemConfigId = payload
  },
  updateIsPreviewMode(state, payload) {
    state.isPreviewMode = payload
  },
  setNotifications(state, notifications) {
    state.notifications = notifications
  },
  setShowNotifications(state, newValue) {
    state.showNotifications = newValue
  },
  setScrollInMobilView(state, payload) {
    state.scrollInMobilView = payload
  },
  setScrollInDesktopView(state, payload) {
    state.scrollInDesktopView = payload
  },
  setIsDndInProgress(state, payload) {
    if (payload) {
      state.isDndInProgress++
    } else {
      state.isDndInProgress--
    }
  },
  updateSuperTagsWithLogo(state, payload) {
    state.superTagsWithLogo = payload
  },
  updateCustomTags(state, payload) {
    state.customTags = payload
  },
}

export const actions = {
  addToClipboard({ commit, state, getters }, box) {
    const boxes = JSON.parse(JSON.stringify(getters.getBoxes(0, 0)))
    boxes.push(box)
    commit('updateGroupField', { blockIndex: 0, groupIndex: 0, field: 'boxes', value: boxes })
  },
  addToBeginningOfClipboard({ commit, state, getters }, box) {
    const boxes = JSON.parse(JSON.stringify(getters.getBoxes(0, 0)))
    boxes.unshift(box)
    commit('updateGroupField', { blockIndex: 0, groupIndex: 0, field: 'boxes', value: boxes })
  },
  reIndexAndUpdateBoxes({ commit, state }, payload) {
    payload.boxes.forEach((box, index) => {
      box.position = index
    })
    commit('updateGroupField', { blockIndex: payload.blockIndex, groupIndex: payload.groupIndex, field: 'boxes', value: payload.boxes })
  },
  async initNotifications({ commit }, id) {
    const notifications = await window.$nuxt.$frontpageManager.getNotifications()

    commit('setNotifications', notifications)
    return notifications
  },
  async removeNotificationById({ commit }, id) {
    const notifications = await window.$nuxt.$frontpageManager.removeNotificationById(id)

    commit('setNotifications', notifications)
    return notifications
  },
  async initSocket({ commit }) {
    await window.$nuxt.$frontpageManager.initSocket()
    commit('socketInited', true)
  },
  async closeSocket({ commit }) {
    await window.$nuxt.$frontpageManager.closeSocket()
    commit('socketInited', false)
  },
  async sendArticles({ commit, state }, ids) {
    await window.$nuxt.$frontpageManager.setArticles(ids)
  },
}

export const getters = {
  isSocketInited(state) {
    return state.socketInited
  },
  getBlocks: (state) => {
    return state.blocks
  },
  getLockBlocks: (state) => {
    return state.lockBlocks
  },
  getBlock: state => (blockIndex) => {
    return state.blocks[blockIndex]
  },
  getGroup: state => (blockIndex, groupIndex) => {
    return state.blocks[blockIndex].groups[groupIndex]
  },
  getBlocksWithoutExtra: (state) => {
    // TODO: position = 0 => TEMP, 1000 => breaking
    return state.blocks
      .filter(block => block && parseInt(block.position) !== 0 && parseInt(block.position) !== 1000)
  },
  getBlocksWithoutBreaking: (state) => {
    // TODO: position = 0 => TEMP, 1000 => breaking
    return state.blocks
      .filter(block => block && parseInt(block.position) !== 1000)
  },
  getBoxes: state => (blockIndex, groupIndex) => {
    return state.blocks[blockIndex].groups[groupIndex].boxes
  },
  getLayouts: (state) => {
    return state.layouts.filter(layout => layout.id !== 1) // A vágólap layout-ot nem engedjük beállítani sehol
  },
  getGroupMeta: state => (blockIndex, groupPosition) => {
    return state.blocks[blockIndex].layout.groupMetas.find(groupMeta => groupMeta.position === groupPosition)
  },
  getBox: state => (blockIndex, groupIndex, boxIndex) => {
    return state.blocks[blockIndex].groups[groupIndex].boxes[boxIndex]
  },
  getBoxEditor: (state) => {
    return state.boxEditor
  },
  getEditedBox: (state) => {
    return state.boxEditor.editedBox
  },
  isElementAlreadyConnected: state => (elementType, elementId) => {
    for (const block of state.blocks) {
      for (const group of block.groups) {
        for (const box of group.boxes) {
          if (box !== null && box.connectedType === elementType
            && parseInt(box.connectedValue) === parseInt(elementId)) { // TODO: a connectedValue lehet string is, ezt át kell gondolni
            return true
          }
        }
      }
    }
    return false
  },
  getActiveItemConfigId: (state) => {
    return state.activeItemConfigId
  },
  isPreviewMode: (state) => {
    return state.isPreviewMode
  },
  getBoxByConnectedData: state => (elementType, elementId) => {
    for (let blockIndex = 0; blockIndex < state.blocks.length; blockIndex++) {
      const block = state.blocks[blockIndex]
      for (let groupIndex = 0; groupIndex < block.groups.length; groupIndex++) {
        const group = block.groups[groupIndex]
        for (let boxIndex = 0; boxIndex < group.boxes.length; boxIndex++) {
          if (group.boxes[boxIndex] !== null && group.boxes[boxIndex].connectedType === elementType
            && parseInt(group.boxes[boxIndex].connectedValue) === parseInt(elementId)) { // TODO: a connectedValue lehet string is, ezt át kell gondolni, lehet össze kellene vonni az isElementAlreadyConnected-al
            const box = group.boxes[boxIndex]
            return { box, blockIndex, groupIndex, boxIndex }
          }
        }
      }
    }

    return false
  },
  getClipboard: (state, getters) => {
    return getters.getBlock(0) // TODO: position = 0 => TEMP (később lehet az blockId 0 lesz majd a temp esetén)
  },
  clipboardSize: (state, getters) => {
    return getters.getClipboard.groups[0].boxes.length // A clipboard-nak mindig a 0. group-ját nézzük
  },
  getNotifications: (state) => {
    return state.notifications
  },
  getNotificationsCount: (state) => {
    return state.notifications ? state.notifications.length : 0
  },
  getBoxEditorWarnings: (state) => {
    return state.boxEditor.warnings
  },
  getBoxEditorErrors: (state) => {
    return state.boxEditor.errors
  },
  getBoxEditorStatus: (state) => {
    return state.boxEditor.status
  },
  getBoxEditorGroupMeta: (state) => {
    return state.boxEditor.groupMeta
  },
  getScrollInMobilView: (state) => {
    return state.scrollInMobilView
  },
  getScrollInDesktopView: (state) => {
    return state.scrollInDesktopView
  },
  isDndInProgress: (state) => {
    return state.isDndInProgress > 0
  },
  getSuperTagsWithLogo: (state) => {
    return state.superTagsWithLogo
  },
  isShowBreaking: (state, getters) => {
    return getters.getBreakingBlock !== null
  },
  getBreakingBlock: (state) => {
    const breakingBlockArray = state.blocks.filter(block => block.position === 1000)
    if (breakingBlockArray.length > 0) {
      return breakingBlockArray[0]
    }

    return null
  },
  getBreakingBlockIndex: (state, getters) => {
    return state.blocks.indexOf(getters.getBreakingBlock)
  },
  getCustomTags: (state) => {
    return state.customTags
  },
  getCustomTag: state => (uniqueItemId) => {
    return state.customTags.filter(uniqueItem => uniqueItemId === uniqueItem.id)[0]
  },
}
