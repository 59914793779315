export default $axios => ({
  getList(page = 1, perPage = 20, orderBy = 'name', orderDesc = false, filterQuery = {}) {
    return $axios.$get('/api/users', {
      params: {
        page,
        perPage,
        orderBy,
        orderType: orderDesc === false ? 'ASC' : 'DESC',
        ...filterQuery,
      },
    })
  },

  getAll() {
    return $axios.$get('/api/users', {
      params: {
        all: true,
      },
    })
  },

  getById(id) {
    return $axios.$get('/api/users/' + id)
  },

  async post(postData) {
    return await $axios.$post('/api/users', postData)
  },

  getTypeahead(typed, limit = 5) {
    return $axios.$get('/api/search/users?limit=' + limit + '&term=' + typed, { progress: false })
  },

  geBySchedule(scheduleSlug) {
    return $axios.$get('/api/users/schedule/' + scheduleSlug)
  },

  async sendScheduleEmail(from, to) {
    return await $axios.$post('/api/users/schedule/send', { from, to })
  },
})
