/**
 * A store felől jövő requestek kiküldése a socketre, és az onnan jövő üzenetek beírására az osztály feladata
 */
export class FrontpageManager {
  constructor(ctx, tabId) {
    this.ctx = ctx
    this.tabId = tabId
    this.store = ctx.$store
  }

  initSocket() {
    this.socket = this.ctx.$nuxtSocket({
      name: 'default',
      persist: 'frontpageSocket',
      channel: '/frontpage',
      reconnection: true,
      teardown: false,
      forceNew: false,
      auth: {
        token: this.store.getters['auth/getAccessToken'],
        tabId: this.tabId,
      },
    })

    this.socket.on('frontpageNotificationsChanged', (message, _) => {
      console.log('frontpageNotificationsChanged; message: ', message)

      this.store.commit('frontpage/setNotifications', message.notifications)
    })

    /*
    this.socket.on('reconnect', () => {
      console.log('RECONNECTED!')
      this.restartConnection(this.store.state.pageLock.page).then((response) => {
        // this.store.commit('pageLock/setLockData', response)
      })
    })
    */
  }

  async emit(event, message = null) {
    if (!this.socket) {
      console.log('socket is not inited yet! request dropped;')
      return
    }

    return await this.store.dispatch(
      '$nuxtSocket/emit',
      {
        socket: this.socket,
        evt: event,
        msg: message,
      },
    )
  }

  async restartConnection(page) {
    const response = await this.emit('restartConnection', { page })
    console.log('reconnect response: ', response)
    return response
  }

  async getNotifications() {
    const response = await this.emit('getNotifications')
    console.log('getLocks response: ', response)
    return response
  }

  async removeNotificationById(notificationId) {
    const response = await this.emit('removeNotificationById', { notificationId })
    console.log('removeNotificatoinById response: ', response)

    return response
  }

  async setArticles(ids) {
    const response = await this.emit('setArticles', { ids })
    console.log('updateArticles response: ', response)

    return response
  }

  closeSocket() {
    if (this.socket) {
      this.socket.disconnect()
    }
  }
}
