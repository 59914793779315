
export default {
  props: {
    type: {
      type: String,
      default: 'text',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    errors: {
      type: Array,
      default: null,
    },
    label: {
      type: String,
      default: 'Input',
    },
  },
  computed: {
    content() {
      return this.value
    },
    state() {
      return this.errors !== null ? false : null
    },
  },
  methods: {
    handleInput(e) {
      this.$emit('input', e)
    },
    handleEnter(e) {
      this.$bus.$emit('enter', e)
    },
  },
}
