export default $moment => ({
  get(article) {
    const date = $moment.unix(article.pubDate)
    const mainSuperTagSlug = article.mainSuperTag ? article.mainSuperTag.slug : 'hianyzo-main-super-tag'

    return '/' + mainSuperTagSlug + '/'
      + date.format('YYYY')
      + '/' + date.format('MM')
      + '/' + date.format('DD')
      + '/' + article.slug
  },
})
