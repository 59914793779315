export const state = () => ({
  refreshToken: null,
  accessToken: null,
  loggedInUser: null,
  isAuthenticated: false,
})

export const mutations = {
  setRefreshToken(state, refreshToken) {
    state.refreshToken = refreshToken
  },
  setAccessToken(state, accessToken) {
    state.accessToken = accessToken
  },
  // sets state with user information and toggles
  // isAuthenticated from false to true
  setLoggedInUser(state, user) {
    state.loggedInUser = user
    state.isAuthenticated = true
  },
  // delete all auth and user information from the state
  clearUserData(state) {
    state.refreshToken = null
    state.accessToken = null
    state.loggedInUser = null
    state.isAuthenticated = false
  },
}

export const getters = {
  getLoggedInUser(state) {
    return state.loggedInUser
  },
  getAccessToken(state) {
    return state.accessToken
  },
  getRefreshToken(state) {
    return state.refreshToken
  },
  getLoggedInUserName(state) {
    return state.loggedInUser.lastName + ' ' + state.loggedInUser.firstName
  },
}
