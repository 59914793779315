export default $axios => ({
  getList(page = 1, perPage = 20, orderBy = 'id', orderDesc = true, filterQuery = {}) {
    return $axios.$get('/api/staff/votes', {
      params: {
        page,
        perPage,
        orderBy,
        orderType: orderDesc === false ? 'ASC' : 'DESC',
        ...filterQuery,
      },
    })
  },

  getAll(filterQuery) {
    return $axios.$get('/api/staff/votes', {
      params: {
        all: true,
        ...filterQuery,
      },
    })
  },

  getBySlug(slug) {
    return $axios.$get('/api/staff/votes/' + slug)
  },

  async post(postData) {
    return await $axios.$post('/api/staff/votes/' + postData.slug + '/' + postData.answer)
  },
})
