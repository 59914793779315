
import { detectAnyAdblocker } from 'vue-adblock-detector'

export default {
  mounted() {
    fetch('https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js').then((_) => {
      if (_.redirected) {
        this.adBlockerDetected()
      }
    }).catch((_) => {
      this.adBlockerDetected()
    })

    const self = this
    setTimeout(() => {
      if (window.getComputedStyle(document.getElementById('tamogatas-link'))
        .getPropertyValue('display') === 'none'
      ) {
        self.adBlockerDetected()
      }
    }, 150)

    detectAnyAdblocker().then((detected) => {
      if (detected) {
        self.adBlockerDetected()
      }
    })
  },
  methods: {
    adBlockerDetected() {
      this.$store.commit('setAdBlocked', true)
      this.$bvModal.show('detect-ab')
    },
  },
}
