
export default {
  computed: {
    showScreenshotModal: {
      get() {
        return this.$store.getters['layout/isShowScreenshotModal']
      },
      set(newValue) {
        this.$store.commit('layout/setShowScreenshotModal', newValue)
      },
    },
  },
  watch: {
    isShowScreenshotModal(newValue) {
      if (newValue) {
        this.screenshot = {
          sourceUrl: '',
          title: '',
          screenshot: '',
        }
      }
    },
  },
  data() {
    return {
      isWaiting: false,
      screenshot: {
        title: '',
        sourceUrl: '',
        screenshot: '',
      },
    }
  },
  methods: {
    async newScreenshot() {
      this.screenshot.screenshot = ''
      this.isWaiting = true
      await this.$screenshotRepository.post(this.screenshot).then((response) => {
        this.screenshot.title = response.title
        this.screenshot.sourceUrl = response.sourceUrl
        this.screenshot.screenshot = response.screenshot
        this.$toast.success('Sikeres mentés', { duration: 1000 })
        if (this.$route.name === 'screenshot_list') {
          this.$nuxt.refresh()
        }
      }).catch((_) => {
        this.$toast.error('Sikertelen mentés', { duration: 1000 })
      }).finally((_) => {
        this.isWaiting = false
      })
    },
  },
}
