export default ($store, $error) => ({
  setPermissionChangeListener() {
    $store.subscribe((mutation) => {
      // Amikor újratölti magát a user az aktuális oldal jogosultságát is ellenőrizzük
      if (mutation.type === 'auth/setLoggedInUser' && mutation.payload.value) {
        this.checkRouteAccess($store.state.currentRoute)
      }
    })
  },

  throwPermissionError() {
    return $error({
      statusCode: 401,
      message: 'Nincs jogosultságod az oldal megnyitásához.',
    })
  },

  /**
   * A kapott route-ra ellenőrni a belépett user jogosultságát
   */
  checkRouteAccess(route) {
    if (!$store.getters['auth/getLoggedInUser']) {
      // Nincs user nem tudunk validálni
      return false
    }

    const user = $store.getters['auth/getLoggedInUser']
    const permissions = user.permissions

    switch (route.name) {
      case 'frontpage_edit':
        if (!permissions.includes('frontpage')) {
          return this.throwPermissionError()
        }
        break

      case 'author_list':
      case 'author_edit':
        if (!permissions.includes('author')) {
          return this.throwPermissionError()
        }
        break

      case 'article_list':
      case 'live_blog_list':
        if (!(permissions.includes('article-base')
          || permissions.includes('article-pr')
          || permissions.includes('article-general'))
        ) {
          return this.throwPermissionError()
        }
        break

      case 'live_blog_child_edit':
      case 'live_blog_edit':
      case 'article_edit':
        if (!(permissions.includes('article-base')
          || permissions.includes('article-pr')
          || permissions.includes('article-general'))
        ) {
          return this.throwPermissionError()
        }
        break

      case 'notification_list':
      case 'notification_edit':
        if (!permissions.includes('notification')) {
          return this.throwPermissionError()
        }
        break

      case 'doc_list':
      case 'doc_edit':
        if (!permissions.includes('doc')) {
          return this.throwPermissionError()
        }
        break

      case 'voting_list':
      case 'voting_edit':
        if (!permissions.includes('vote')) {
          return this.throwPermissionError()
        }
        break

      case 'super_tags_list':
      case 'super_tags_edit':
        if (!permissions.includes('super-tag')) {
          return this.throwPermissionError()
        }
        break

      case 'tags_list':
      case 'tags_edit':
        if (!permissions.includes('tag')) {
          return this.throwPermissionError()
        }
        break

      case 'user_list':
        if (!permissions.includes('user-list') && !permissions.includes('hr')) {
          return this.throwPermissionError()
        }
        break

      case 'user_edit':
        if (route.name === 'user_edit' && parseInt(route.params.id) === parseInt(user.id)) {
          // Saját magunk szerkesztését engedjük, user permissiontől független!
          break
        }
        if (!permissions.includes('user-edit') && !permissions.includes('hr')) {
          return this.throwPermissionError()
        }
        break

      case 'locks':
        if (!permissions.includes('lock')) {
          return this.throwPermissionError()
        }
        break

      case 'remote_debug':
        if (!permissions.includes('remote-debug')) {
          return this.throwPermissionError()
        }
        break

      case 'supporter_list':
      case 'supporter_edit':
        if (!permissions.includes('tamogatas')) {
          return this.throwPermissionError()
        }
        break

      case 'unique_group_list':
        if (!permissions.includes('unique-group')) {
          return this.throwPermissionError()
        }
        break

      case 'unique_group_edit':
        if (!permissions.includes('unique-group') && !permissions.includes('youth')
            && !permissions.includes('after') && !permissions.includes('frontpage-widgets')
            && !permissions.includes('trending-tags')
            && !permissions.includes('map-descriptions')) {
          return this.throwPermissionError()
        }
        break

      case 'statistics_list':
        if (!permissions.includes('statistics')) {
          return this.throwPermissionError()
        }
        break

      case 'first_name_list':
        if (!permissions.includes('first-name-decider')) {
          return this.throwPermissionError()
        }
        break

      case 'schedule_list':
        if (!permissions.includes('schedule-list')) {
          return this.throwPermissionError()
        }
        break

      case 'screenshot_list':
        if (!permissions.includes('screenshot')) {
          return this.throwPermissionError()
        }
        break

      case 'listing_box_list':
      case 'listing_box_edit':
        if (!permissions.includes('listing-box') && !permissions.includes('listing-box-admin')) {
          return this.throwPermissionError()
        }
        break

      case 'pronounce_list':
      case 'pronounce_edit':
        if (!permissions.includes('speech')) {
          return this.throwPermissionError()
        }
        break
    }
  },

  /**
   * Ellenőrzi, hogy a belépett usernek megvan-e a paraméterben küldött permissionje
   * requiredPermissions példa: 'frontpage'
   */
  hasPermission(requiredPermission) {
    return this.hasPermissions([requiredPermission])
  },

  /**
   * Ellenőrzi, hogy a belépett usernek megvannak-e a paraméterben küldött permissionjei
   * requiredPermissions példa: ['frontpage', 'notification']
   */
  hasPermissions(requiredPermissions) {
    return $store.getters['auth/getLoggedInUser']
      && $store.getters['auth/getLoggedInUser'].permissions
      && requiredPermissions
      && requiredPermissions.every(permission => $store.getters['auth/getLoggedInUser'].permissions.includes(permission))
  },

  hasOnePermission(onePermissionRequired) {
    return $store.getters['auth/getLoggedInUser']
      && $store.getters['auth/getLoggedInUser'].permissions
      && onePermissionRequired
      && onePermissionRequired.some(permission => $store.getters['auth/getLoggedInUser'].permissions.includes(permission))
  },
})
