
import { mapGetters } from 'vuex'

import AdBlockBlock from '../components/AdBlockBlock'
import DangerBrowserModal from '~/components/Shared/Modals/DangerBrowserModal'
import LeftLoginScreen from '~/components/Login/LeftLoginScreen'
import RightLoginScreen from '~/components/Login/RightLoginScreen'
import TopHeader from '~/components/Layout/TopHeader'
import Sidebar from '~/components/Layout/Sidebar'

export default {
  components: { DangerBrowserModal, Sidebar, TopHeader, LeftLoginScreen, RightLoginScreen, AdBlockBlock },
  asyncData(context) {
    return {
      isClosing: false,
      isOpening: false,
    }
  },
  computed: {
    ...mapGetters({
      isSidebarOpen: 'sidebar/getSidebarState',
      isRootSocketInited: 'isSocketInited',
      loggedInUser: 'auth/getLoggedInUser',
    }),
    isGrayLayout() {
      const darkRoutes = ['live_blog_list', 'article_list']
      return darkRoutes.includes(this.$nuxt.$route.name)
    },
    showLayout() {
      return this.$store.getters['auth/getLoggedInUser'] || this.isClosing
    },
    showLogin() {
      return !this.$store.getters['auth/getLoggedInUser'] || this.isOpening || this.isClosing
    },
  },
  watch: {
    loggedInUser(newValue, oldValue) {
      const self = this
      console.log('### loggedInUser watch run!', newValue)
      if (newValue && !oldValue) {
        this.setSentryUser()
        this.initRootSocket()
        console.log('### redirect, lastPage: ' + this.$cookies.get('lastPage'))
        this.$router.replace(this.$cookies.get('lastPage') ?? '/articles')
        this.isOpening = true
        setTimeout(function() {
          self.isOpening = false
        }, 900)
        return
      }

      if (!newValue) {
        this.closeSockets()
        this.isClosing = true
        setTimeout(function() {
          self.isClosing = false
        }, 900)
      }
    },
    isRootSocketInited(newValue) {
      if (newValue) {
        this.initNamespaces()
      }
    },
  },
  mounted() {
    // Feliratkozás ha változna a permission-öm
    this.$permissionChecker.setPermissionChangeListener()
    const self = this
    window.onerror = function(msg, url, line) {
      console.log('window onerror!', msg, url, line)
      self.$store.commit('addLog', { msg, url, line })
    }
    console.log('isAuthenticated @mounted: ', this.$store.getters['auth/getLoggedInUser'])
    if (this.$store.getters['auth/getLoggedInUser']) {
      this.setSentryUser()
      this.initRootSocket()
    }

    // TODO: 2023-05-01 után törölhető
    this.$cookies.remove('auth._token.local')
    this.$cookies.remove('auth._refresh_token.local')
    this.$cookies.remove('auth._refresh_token_expiration.local')
    this.$cookies.remove('auth._token_expiration.local')
  },
  methods: {
    setSentryUser() {
      this.$sentry.setUser({
        id: this.$store.getters['auth/getLoggedInUser'].id,
        name: this.$store.getters['auth/getLoggedInUser'].lastName + ' ' + this.$store.getters['auth/getLoggedInUser'].firstName,
        email: this.$store.getters['auth/getLoggedInUser'].email,
        tabId: this.$store.state.tabId,
      })
    },
    initRootSocket() {
      console.log('initRootSocket called!')
      this.$store.dispatch('initRootSocket')
    },
    initNamespaces() {
      console.log('initNamespaces called!')
      this.$store.dispatch('frontpage/initSocket')
      this.$store.dispatch('locks/initSocket')
    },
    closeSockets() {
      console.log('closeSockets called!')
      this.$store.dispatch('closeRootSocket')
      this.$store.dispatch('locks/closeSocket')
      this.$store.dispatch('frontpage/closeSocket')
    },
  },
  head() {
    return {
      title: this.$sites.getMy('name') + ' admin',
      htmlAttrs: {
        lang: 'hu',
      },
    }
  },
}
