
import { mapGetters } from 'vuex'

export default {
  props: {
    showMessage: {
      type: Boolean,
      default: false,
    },
    requiredPermissions: {
      type: Array,
      default: null,
    },
    onePermissionRequired: {
      type: Array,
      default: null,
    },
  },
  fetch() {
    this.recalculateHasAccess()
  },
  computed: {
    ...mapGetters({
      loggedInUser: 'auth/getLoggedInUser',
    }),
  },
  data() {
    return {
      hasAccess: false,
    }
  },
  watch: {
    loggedInUser() {
      this.recalculateHasAccess()
    },
  },
  methods: {
    recalculateHasAccess() {
      this.hasAccess = this.$permissionChecker.hasPermissions(this.requiredPermissions)
        || this.$permissionChecker.hasOnePermission(this.onePermissionRequired)
    },
  },
}
