import Vue from 'vue'
import { getSrcSet, getTypedImage, getOrigImage, getUploadsPath } from '../helpers/typedImageHelper'
import TypedImage from '~/components/Shared/TypedImage'

export default (context, inject) => {
  Vue.component(
    'typed-image',
    TypedImage,
  )

  inject('getTypedImage', getTypedImage)
  inject('getSrcSet', getSrcSet)
  inject('getOrigImage', getOrigImage)
  inject('getUploadsPath', getUploadsPath)
}
