export default function({ store, route }) {
  store.commit('resetLogs')
  store.commit('setCurrentRoute', {
    name: route.name,
    fullPath: route.fullPath,
    params: route.params,
  })

  if (process.client && store.getters.isSocketInited) {
    store.dispatch('syncCurrentRoute', {
      name: route.name,
      fullPath: route.fullPath,
      params: route.params,
    })
  }
}
