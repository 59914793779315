export const state = () => ({
  connectedUsers: null,
})

export const mutations = {
  setConnectedUsers(state, connectedUsers) {
    state.connectedUsers = connectedUsers
  },
}

export const actions = {
  async fetchConnectedUsers({ commit }) {
    const connectedUsers = await window.$nuxt.$rootManager.getUsers()
    commit('setConnectedUsers', connectedUsers)
  },
}

export const getters = {
  getConnectedUsers(state) {
    return state.connectedUsers
  },
}
