export const state = () => ({
  showScreenshotModal: false,
  showPersonOnDutyModal: false,
  showServiceRequestModal: false,
  showShortCutModal: false,
  showPipModal: false,
  showBroadcastModal: false,
  showClearCookie: false,
})

export const mutations = {
  setShowScreenshotModal(state, show) {
    state.showScreenshotModal = show
  },
  setShowPersonOnDutyModal(state, show) {
    state.showPersonOnDutyModal = show
  },
  setShowServiceRequestModal(state, show) {
    state.showServiceRequestModal = show
  },
  setShowShortCutModal(state, show) {
    state.showShortCutModal = show
  },
  setShowPipModal(state, show) {
    state.showPipModal = show
  },
  setShowBroadcastModal(state, show) {
    state.showBroadcastModal = show
  },
  setShowClearCookie(state, show) {
    state.showClearCookie = show
  },
}

export const getters = {
  isShowScreenshotModal(state) {
    return state.showScreenshotModal
  },
  isShowPersonOnDutyModal(state) {
    return state.showPersonOnDutyModal
  },
  isShowServiceRequestModal(state) {
    return state.showServiceRequestModal
  },
  isShowShortCutModal(state) {
    return state.showShortCutModal
  },
  isShowPipModal(state) {
    return state.showPipModal
  },
  isShowBroadcastModal(state) {
    return state.showBroadcastModal
  },
  isShowClearCookie(state) {
    return state.showClearCookie
  },
}
