
export default {
  data() {
    return {
      resetStage: 1,
      login: {
        resetEmail: null,
        resetToken: null,
        resetPassword: null,
        resetAgain: null,
      },
      showLoginError: false,
      passwordError: '',
    }
  },
  async mounted() {
    if (this.$route.query
        && 'stage' in this.$route.query && this.$route.query.stage === 'password-reset'
        && 'token' in this.$route.query && this.$route.query.token.length === 50) {
      if (this.$store.getters['auth/getLoggedInUser']) {
        await this.$router.push('/articles')
      } else {
        this.$cookies.remove('lastPage')
        await this.$forgotPasswordRepository.validateToken(this.$route.query.token).then((response) => {
          this.login.resetToken = this.$route.query.token
          this.login.resetEmail = response.user.email
          this.resetStage = 3
        }).catch((error) => {
          console.log('Baj van!', error)
        }).finally((_) => {
          this.$router.push('/login')
        })
      }
    }
  },
  methods: {
    async sendToken() {
      if (this.login.resetEmail && this.login.resetEmail.includes('@') && this.login.resetEmail.includes('.')) {
        await this.$forgotPasswordRepository.sendToken({
          email: this.login.resetEmail,
        }).then((_) => {
          this.resetStage = 2
        }).catch((_) => {
          this.showLoginError = true
        })
      } else {
        this.showLoginError = true
      }
    },
    async resetPassword() {
      if (this.login.resetPassword.length > 5 && this.login.resetPassword === this.login.resetAgain) {
        await this.$forgotPasswordRepository.resetPassword(this.login.resetToken, {
          password: this.login.resetPassword,
        }).then((_) => {
          this.resetStage = 4
        })
      } else {
        this.passwordError = this.login.resetPassword.length <= 5
          ? 'A megadott jelszó a túl rövid!'
          : 'A megadott jelszavak nem egyeznek meg!'
        this.showLoginError = true
      }
    },
    clearFields() {
      this.login.resetEmail = null
      this.login.resetToken = null
      this.login.resetPassword = null
      this.login.resetAgain = null
      this.showLoginError = false
      this.resetStage = 0
      this.$emit('hidePasswordReset')
    },
    hideError() {
      this.showLoginError = false
    },
  },
}
