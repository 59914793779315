import questionValidator from './questionValidator'

export default (quiz) => {
  if (!quiz?.questions) {
    console.log('validálás: nincsenek a kvízben question-ök')
    return null
  }

  const errors = {}
  quiz.questions.forEach((question) => {
    const validatorResult = questionValidator(question)
    if (validatorResult.valid === false) {
      errors[question.temporaryId] = validatorResult.errors
    }
  })

  console.log('quizValidator', errors)
  return errors
}
