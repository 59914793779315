export default $axios => ({
  search(
    page = 1,
    perPage = 20,
    orderByField = 'pubDate',
    orderDesc = true,
    searchParams = {},
    searchWithoutMirror = false,
    raw = false,
    term = null,
  ) {
    const orderBy = {}
    orderBy[orderByField] = orderDesc ? 'DESC' : 'ASC'
    return $axios.$get('/api/search', {
      headers: {
        'X-Admin-Request': true,
      },
      params: {
        oldal: page,
        perPage,
        orderBy,
        ...searchParams,
        searchWithoutMirror: searchWithoutMirror ? 1 : 0,
        term,
        raw: raw ? 1 : 0,
      },
    })
  },

  getBySlug(slug, progress = true) {
    return $axios.$get('/api/articles/' + slug, {
      headers: {
        'X-Admin-Request': true,
      },
      progress,
    })
  },

  softDeleteBySlug(slug, progress = true) {
    return $axios.$delete('/api/articles/' + slug, {
      headers: {
        'X-Admin-Request': true,
      },
      progress,
    })
  },

  restoreBySlug(slug, progress = true) {
    return $axios.$post('/api/articles/' + slug + '/restore', {
      headers: {
        'X-Admin-Request': true,
      },
      progress,
    })
  },

  async getById(id, searchWithoutMirror) {
    const articles = await $axios.$get('/api/search', {
      params: {
        oldal: 1,
        perPage: 1,
        filters: { id: [id] },
        searchWithoutMirror: searchWithoutMirror ? 1 : 0,
      },
    })

    return articles.items[0] ? articles.items[0] : null
  },

  async post(postData, softSave) {
    return await $axios.$post('/api/articles' + (softSave ? '/soft' : ''), postData)
  },

  getSubArticlesByParentId(parentId, perPage = 30, page = 1, extraFilters = {}) {
    return $axios.$get('/api/articles/' + parentId + '/items', {
      params: {
        page,
        perPage,
        ...extraFilters,
      },
    })
  },

  getRecommender(slug, version) {
    return $axios.$get('/api/recommended/' + slug + '?v=' + version)
  },

  getVersions(id) {
    return $axios.$get('/api/articles/' + id + '/versions')
  },

  getVersionBySlug(slug, progress = true) {
    return $axios.get('/api/articles/' + slug + '/version', { progress })
  },

  postMirrorRequest(site, slug) {
    return $axios.post('/api/mirror/' + site + '/' + slug)
  },

  deleteMirrorRequest(site, slug) {
    return $axios.post('/api/mirror/' + site + '/' + slug)
  },

  refreshFacebookEngagement(articleId) {
    return $axios.get('/api/articles/' + articleId + '/facebook-engagement')
  },
})
