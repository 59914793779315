export default () => ({

  hasFlag(article, flagSlug) {
    return article.flags.filter(f => f.slug === flagSlug).length === 1
  },

  isEnglish(article) {
    return article.mainSuperTag?.slug === 'english'
      || article.superTags?.filter(f => f.slug === 'english').length > 0
      || article.superTags?.filter(f => f.name === 'English').length > 0
  },

})
