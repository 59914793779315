export default $axios => ({
  getList(page = 1, perPage = 20, orderBy = 'name', orderDesc = false, filterQuery = {}) {
    return $axios.$get('/tamogatas/users/list', {
      params: {
        page,
        perPage,
        orderBy,
        orderType: orderDesc === false ? 'ASC' : 'DESC',
        ...filterQuery,
      },
    })
  },

  getBySlug(id) {
    return $axios.$get('/tamogatas/user/' + id)
  },

  async post(postData) {
    return await $axios.$post('/tamogatas/user/update/' + postData.id, postData)
  },

  async postpone(postData) {
    return await $axios.$post('/tamogatas/donate/postpone', postData)
  },

  async restart(postData) {
    return await $axios.$post('/tamogatas/donate/continue', postData)
  },

  async cancel(postData) {
    return await $axios.$post('/tamogatas/donate/cancel', postData)
  },
})
