import Y2021PrimariesRepository from '@/api/Y2021PrimariesRepository'
import ListingBoxRepository from '@/api/ListingBoxRepository'
import SuperTagRepository from '~/api/SuperTagRepository'
import TagRepository from '~/api/TagRepository'
import FlagRepository from '~/api/FlagRepository'
import BlockRepository from '~/api/BlockRepository'
import UserRepository from '~/api/UserRepository'
import ArticleRepository from '~/api/ArticleRepository'
import DocRepository from '~/api/DocRepository'
import FileRepository from '~/api/FileRepository'
import AuthorRepository from '~/api/AuthorRepository'
import VotingRepository from '~/api/VotingRepository'
import StaffVoteRepository from '~/api/StaffVoteRepository'
import NotificationRepository from '~/api/NotificationRepository'
import BeamRepository from '~/api/BeamRepository'
import PermissionRepository from '~/api/PermissionRepository'
import ScheduleRepository from '~/api/ScheduleRepository'
import ScheduleItemRepository from '~/api/ScheduleItemRepository'
import SpellCheckRepository from '~/api/SpellCheckRepository'
import SupporterRepository from '~/api/SupporterRepository'
import LiveBlogEventRepository from '~/api/LiveBlogEventRepository'
import LiveScoreRepository from '~/api/LiveScoreRepository'
import UniqueGroupRepository from '~/api/UniqueGroupRepository'
import UniqueItemRepository from '~/api/UniqueItemRepository'
import LayoutRepository from '~/api/LayoutRepository'
import ForgotPasswordRepository from '~/api/ForgotPasswordRepository'
import SecurityRepository from '~/api/SecurityRepository'
import ScreenshotRepository from '~/api/ScreenshotRepository'
import FrontendRepository from '~/api/FrontendRepository'
import EpisodeRepository from '~/api/EpisodeRepository'
import PodcastRepository from '~/api/PodcastRepository'
import QuizRepository from '~/api/QuizRepository'
import HrDepartmentRepository from '~/api/HrDepartmentRepository'
import HrPostRepository from '~/api/HrPostRepository'
import TrendingTagRepository from '~/api/TrendingTagRepository'
import SpeechRepository from '~/api/SpeechRepository'
import PronounceRepository from '~/api/PronounceRepository'

export default ({ $axios }, inject) => {
  const y2021PrimariesRepository = Y2021PrimariesRepository($axios)
  const superTagRepository = SuperTagRepository($axios)
  const tagRepository = TagRepository($axios)
  const flagRepository = FlagRepository($axios)
  const blockRepository = BlockRepository($axios)
  const userRepository = UserRepository($axios)
  const articleRepository = ArticleRepository($axios)
  const docRepository = DocRepository($axios)
  const fileRepository = FileRepository($axios)
  const authorRepository = AuthorRepository($axios)
  const votingRepository = VotingRepository($axios)
  const staffVoteRepository = StaffVoteRepository($axios)
  const notificationRepository = NotificationRepository($axios)
  const beamRepository = BeamRepository($axios)
  const permissionRepository = PermissionRepository($axios)
  const scheduleRepository = ScheduleRepository($axios)
  const scheduleItemRepository = ScheduleItemRepository($axios)
  const spellCheckRepository = SpellCheckRepository($axios)
  const supporterRepository = SupporterRepository($axios)
  const liveBlogEventRepository = LiveBlogEventRepository($axios)
  const liveScoreRepository = LiveScoreRepository($axios)
  const uniqueGroupRepository = UniqueGroupRepository($axios)
  const uniqueItemRepository = UniqueItemRepository($axios)
  const layoutRepository = LayoutRepository($axios)
  const forgotPasswordRepository = ForgotPasswordRepository($axios)
  const securityRepository = SecurityRepository($axios)
  const screenshotRepository = ScreenshotRepository($axios)
  const frontendRepository = FrontendRepository($axios)
  const listingBoxRepository = ListingBoxRepository($axios)
  const podcastRepository = PodcastRepository($axios)
  const episodeRepository = EpisodeRepository($axios)
  const quizRepository = QuizRepository($axios)
  const hrDepartmentRepository = HrDepartmentRepository($axios)
  const hrPostRepository = HrPostRepository($axios)
  const trendingTagRepository = TrendingTagRepository($axios)
  const speechRepository = SpeechRepository($axios)
  const pronounceRepository = PronounceRepository($axios)

  inject('y2021PrimariesRepository', y2021PrimariesRepository)
  inject('superTagRepository', superTagRepository)
  inject('tagRepository', tagRepository)
  inject('flagRepository', flagRepository)
  inject('blockRepository', blockRepository)
  inject('userRepository', userRepository)
  inject('articleRepository', articleRepository)
  inject('docRepository', docRepository)
  inject('fileRepository', fileRepository)
  inject('authorRepository', authorRepository)
  inject('votingRepository', votingRepository)
  inject('staffVoteRepository', staffVoteRepository)
  inject('notificationRepository', notificationRepository)
  inject('beamRepository', beamRepository)
  inject('permissionRepository', permissionRepository)
  inject('scheduleRepository', scheduleRepository)
  inject('scheduleItemRepository', scheduleItemRepository)
  inject('spellCheckRepository', spellCheckRepository)
  inject('supporterRepository', supporterRepository)
  inject('liveBlogEventRepository', liveBlogEventRepository)
  inject('liveScoreRepository', liveScoreRepository)
  inject('uniqueGroupRepository', uniqueGroupRepository)
  inject('uniqueItemRepository', uniqueItemRepository)
  inject('layoutRepository', layoutRepository)
  inject('forgotPasswordRepository', forgotPasswordRepository)
  inject('securityRepository', securityRepository)
  inject('screenshotRepository', screenshotRepository)
  inject('frontendRepository', frontendRepository)
  inject('listingBoxRepository', listingBoxRepository)
  inject('podcastRepository', podcastRepository)
  inject('episodeRepository', episodeRepository)
  inject('quizRepository', quizRepository)
  inject('hrDepartmentRepository', hrDepartmentRepository)
  inject('hrPostRepository', hrPostRepository)
  inject('trendingTagRepository', trendingTagRepository)
  inject('speechRepository', speechRepository)
  inject('pronounceRepository', pronounceRepository)
}
