const sites = [
  {
    slug: 'telex',
    name: 'Telex',
    cssClass: 'main--t',
    domain: 'telex.hu',
    url: 'https://telex.hu',
    currency: 'Ft',
    verification: 'z21ykhonau2grf12jzdtendo24jb64',
    facebookLogo: 'telex_logo_fb_share.png',
    facebookUrl: 'https://www.facebook.com/telexhu',
    twitterSite: '@telexhu',
    quantcast: '/js/quantcast.js',
    allowMirror: [],
    allowSearchOtherSite: ['transtelex'],
  },
  {
    slug: 'transtelex',
    name: 'Transtelex',
    cssClass: 'main--tt',
    domain: 'transtelex.ro',
    url: 'https://transtelex.ro',
    currency: 'RON',
    verification: 'a7vvntdjpr1clbps6fllneeonra9ks',
    facebookLogo: 'transtelex_logo_fb_share.jpg',
    facebookUrl: 'https://www.facebook.com/transtelex.ro',
    twitterSite: '',
    quantcast: '/js/quantcast-tt.js',
    allowMirror: ['telex'],
    allowSearchOtherSite: [],
  },
]

export default ctx => ({

  getMy(property) {
    const site = sites.find(site => site.slug === ctx.store.getters['commonData/getFrontendSetup'].siteSlug)
    return property === 'url' ? 'https://' + site.domain : site[property]
  },

  isTelex() {
    return this.getMy('slug') === 'telex'
  },

  isTranstelex() {
    return !this.isTelex()
  },

  getSites() {
    return sites
  },

})
