
export default {
  data() {
    return {
      pipUrlError: null,
      pipResponse: null,
      pipData: {
        active: false,
        title: false,
        url: false,
        open: false,
        label: 'élő',
      },
      liveOptions: [
        { label: 'élő', value: 'élő' },
        { label: 'premier', value: 'premier' },
      ],
    }
  },
  computed: {
    showPipModal: {
      get() {
        return this.$store.getters['layout/isShowPipModal']
      },
      set(newValue) {
        this.$store.commit('layout/setShowPipModal', newValue)
      },
    },
  },
  watch: {
    'pipData.url'() {
      if (!this.pipUrlError) {
        return
      }

      this.validatePipUrl()
    },
    showPipModal(newValue) {
      if (newValue) {
        this.initPipModal()
      }
    },
  },
  methods: {
    validatePipUrl() {
      if (!this.pipData.url || this.pipData.url === '') {
        return null
      }

      this.pipUrlError = /^https:\/\/www.facebook.com\/[\w.]*\/videos\/\d*$/.test(this.pipData.url)
      || /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm.test(this.pipData.url)
        ? null
        : ['Érvénytelen formátum!']
    },
    async initPipModal() {
      this.pipResponse = await this.$uniqueGroupRepository.getById(16)
      this.pipData.active = this.pipResponse.uniques.filter(i => i.slug === 'pip-show')[0].content
      this.pipData.title = this.pipResponse.uniques.filter(i => i.slug === 'pip-title')[0].content
      this.pipData.url = this.pipResponse.uniques.filter(i => i.slug === 'pip-url')[0].content
      this.pipData.open = this.pipResponse.uniques.filter(i => i.slug === 'pip-open')[0].content
      this.pipData.label = this.pipResponse.uniques.filter(i => i.slug === 'pip-label')[0].content
      this.showPipModal = true
    },
    setPipModal() {
      this.pipResponse.uniques.filter(i => i.slug === 'pip-show')[0].content = this.pipData.active
      this.pipResponse.uniques.filter(i => i.slug === 'pip-title')[0].content = this.pipData.title
      this.pipResponse.uniques.filter(i => i.slug === 'pip-url')[0].content = this.pipData.url
      this.pipResponse.uniques.filter(i => i.slug === 'pip-open')[0].content = this.pipData.open
      this.pipResponse.uniques.filter(i => i.slug === 'pip-label')[0].content = this.pipData.label
      this.$uniqueGroupRepository.post(this.pipResponse).then((response) => {
        this.$toast.success('Sikeres mentés', { duration: 1200 })
        this.showPipModal = false
      }).catch((_) => {
        this.$toast.error('Sikertelen mentés', { duration: 8000 })
      })
    },
    setLiveLabel(selectedOption) {
      this.pipData.label = selectedOption.value
    },
  },
}
