export const AdBlockBlock = () => import('../../components/AdBlockBlock.vue' /* webpackChunkName: "components/ad-block-block" */).then(c => wrapFunctional(c.default || c))
export const CopyButton = () => import('../../components/CopyButton.vue' /* webpackChunkName: "components/copy-button" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const Visitors = () => import('../../components/Visitors.vue' /* webpackChunkName: "components/visitors" */).then(c => wrapFunctional(c.default || c))
export const ImageCropModal = () => import('../../components/Edit/ImageCropModal.vue' /* webpackChunkName: "components/image-crop-modal" */).then(c => wrapFunctional(c.default || c))
export const Lock = () => import('../../components/Edit/Lock.vue' /* webpackChunkName: "components/lock" */).then(c => wrapFunctional(c.default || c))
export const CookiesDeleteAnimation = () => import('../../components/Layout/CookiesDeleteAnimation.vue' /* webpackChunkName: "components/cookies-delete-animation" */).then(c => wrapFunctional(c.default || c))
export const KisuzemLogo = () => import('../../components/Layout/KisuzemLogo.vue' /* webpackChunkName: "components/kisuzem-logo" */).then(c => wrapFunctional(c.default || c))
export const MessagesIcon = () => import('../../components/Layout/MessagesIcon.vue' /* webpackChunkName: "components/messages-icon" */).then(c => wrapFunctional(c.default || c))
export const MyMenu = () => import('../../components/Layout/MyMenu.vue' /* webpackChunkName: "components/my-menu" */).then(c => wrapFunctional(c.default || c))
export const Sidebar = () => import('../../components/Layout/Sidebar.vue' /* webpackChunkName: "components/sidebar" */).then(c => wrapFunctional(c.default || c))
export const SidebarItem = () => import('../../components/Layout/SidebarItem.vue' /* webpackChunkName: "components/sidebar-item" */).then(c => wrapFunctional(c.default || c))
export const TopHeader = () => import('../../components/Layout/TopHeader.vue' /* webpackChunkName: "components/top-header" */).then(c => wrapFunctional(c.default || c))
export const DeleteButton = () => import('../../components/Lists/DeleteButton.vue' /* webpackChunkName: "components/delete-button" */).then(c => wrapFunctional(c.default || c))
export const EditButton = () => import('../../components/Lists/EditButton.vue' /* webpackChunkName: "components/edit-button" */).then(c => wrapFunctional(c.default || c))
export const ListContent = () => import('../../components/Lists/ListContent.vue' /* webpackChunkName: "components/list-content" */).then(c => wrapFunctional(c.default || c))
export const NumberOfResults = () => import('../../components/Lists/NumberOfResults.vue' /* webpackChunkName: "components/number-of-results" */).then(c => wrapFunctional(c.default || c))
export const ArticleInput = () => import('../../components/Form/ArticleInput.vue' /* webpackChunkName: "components/article-input" */).then(c => wrapFunctional(c.default || c))
export const CheckboxInput = () => import('../../components/Form/CheckboxInput.vue' /* webpackChunkName: "components/checkbox-input" */).then(c => wrapFunctional(c.default || c))
export const DateTimeInput = () => import('../../components/Form/DateTimeInput.vue' /* webpackChunkName: "components/date-time-input" */).then(c => wrapFunctional(c.default || c))
export const ImageInput = () => import('../../components/Form/ImageInput.vue' /* webpackChunkName: "components/image-input" */).then(c => wrapFunctional(c.default || c))
export const PodcastEpisodeInput = () => import('../../components/Form/PodcastEpisodeInput.vue' /* webpackChunkName: "components/podcast-episode-input" */).then(c => wrapFunctional(c.default || c))
export const RadioButtonGroupInput = () => import('../../components/Form/RadioButtonGroupInput.vue' /* webpackChunkName: "components/radio-button-group-input" */).then(c => wrapFunctional(c.default || c))
export const RichTextEditorInput = () => import('../../components/Form/RichTextEditorInput.vue' /* webpackChunkName: "components/rich-text-editor-input" */).then(c => wrapFunctional(c.default || c))
export const SelectInput = () => import('../../components/Form/SelectInput.vue' /* webpackChunkName: "components/select-input" */).then(c => wrapFunctional(c.default || c))
export const SubmitButton = () => import('../../components/Form/SubmitButton.vue' /* webpackChunkName: "components/submit-button" */).then(c => wrapFunctional(c.default || c))
export const TextInput = () => import('../../components/Form/TextInput.vue' /* webpackChunkName: "components/text-input" */).then(c => wrapFunctional(c.default || c))
export const LeftLoginScreen = () => import('../../components/Login/LeftLoginScreen.vue' /* webpackChunkName: "components/left-login-screen" */).then(c => wrapFunctional(c.default || c))
export const RightLoginScreen = () => import('../../components/Login/RightLoginScreen.vue' /* webpackChunkName: "components/right-login-screen" */).then(c => wrapFunctional(c.default || c))
export const CircularProgress = () => import('../../components/Shared/CircularProgress.vue' /* webpackChunkName: "components/circular-progress" */).then(c => wrapFunctional(c.default || c))
export const DraggableDiv = () => import('../../components/Shared/DraggableDiv.vue' /* webpackChunkName: "components/draggable-div" */).then(c => wrapFunctional(c.default || c))
export const PageLockController = () => import('../../components/Shared/PageLockController.vue' /* webpackChunkName: "components/page-lock-controller" */).then(c => wrapFunctional(c.default || c))
export const PermissionCheck = () => import('../../components/Shared/PermissionCheck.vue' /* webpackChunkName: "components/permission-check" */).then(c => wrapFunctional(c.default || c))
export const ShortKey = () => import('../../components/Shared/ShortKey.vue' /* webpackChunkName: "components/short-key" */).then(c => wrapFunctional(c.default || c))
export const SportSelector = () => import('../../components/Shared/SportSelector.vue' /* webpackChunkName: "components/sport-selector" */).then(c => wrapFunctional(c.default || c))
export const TypeaheadItem = () => import('../../components/Shared/TypeaheadItem.vue' /* webpackChunkName: "components/typeahead-item" */).then(c => wrapFunctional(c.default || c))
export const TypedImage = () => import('../../components/Shared/TypedImage.vue' /* webpackChunkName: "components/typed-image" */).then(c => wrapFunctional(c.default || c))
export const UndoRedoHistory = () => import('../../components/Shared/UndoRedoHistory.vue' /* webpackChunkName: "components/undo-redo-history" */).then(c => wrapFunctional(c.default || c))
export const ArticleAdFree = () => import('../../components/Edit/Article/ArticleAdFree.vue' /* webpackChunkName: "components/article-ad-free" */).then(c => wrapFunctional(c.default || c))
export const ArticleBottom = () => import('../../components/Edit/Article/ArticleBottom.vue' /* webpackChunkName: "components/article-bottom" */).then(c => wrapFunctional(c.default || c))
export const ArticleContent = () => import('../../components/Edit/Article/ArticleContent.vue' /* webpackChunkName: "components/article-content" */).then(c => wrapFunctional(c.default || c))
export const ArticleEditorTopBar = () => import('../../components/Edit/Article/ArticleEditorTopBar.vue' /* webpackChunkName: "components/article-editor-top-bar" */).then(c => wrapFunctional(c.default || c))
export const ArticleHead = () => import('../../components/Edit/Article/ArticleHead.vue' /* webpackChunkName: "components/article-head" */).then(c => wrapFunctional(c.default || c))
export const ArticleHeroImageFullWidth = () => import('../../components/Edit/Article/ArticleHeroImageFullWidth.vue' /* webpackChunkName: "components/article-hero-image-full-width" */).then(c => wrapFunctional(c.default || c))
export const ArticleHeroImageNormal = () => import('../../components/Edit/Article/ArticleHeroImageNormal.vue' /* webpackChunkName: "components/article-hero-image-normal" */).then(c => wrapFunctional(c.default || c))
export const ArticleImageCropper = () => import('../../components/Edit/Article/ArticleImageCropper.vue' /* webpackChunkName: "components/article-image-cropper" */).then(c => wrapFunctional(c.default || c))
export const ArticleLead = () => import('../../components/Edit/Article/ArticleLead.vue' /* webpackChunkName: "components/article-lead" */).then(c => wrapFunctional(c.default || c))
export const ArticleLive = () => import('../../components/Edit/Article/ArticleLive.vue' /* webpackChunkName: "components/article-live" */).then(c => wrapFunctional(c.default || c))
export const ArticleModification = () => import('../../components/Edit/Article/ArticleModification.vue' /* webpackChunkName: "components/article-modification" */).then(c => wrapFunctional(c.default || c))
export const ArticleRecommendationFavorite = () => import('../../components/Edit/Article/ArticleRecommendationFavorite.vue' /* webpackChunkName: "components/article-recommendation-favorite" */).then(c => wrapFunctional(c.default || c))
export const ArticleRecommendationImportant = () => import('../../components/Edit/Article/ArticleRecommendationImportant.vue' /* webpackChunkName: "components/article-recommendation-important" */).then(c => wrapFunctional(c.default || c))
export const ArticleRecommendationNewest = () => import('../../components/Edit/Article/ArticleRecommendationNewest.vue' /* webpackChunkName: "components/article-recommendation-newest" */).then(c => wrapFunctional(c.default || c))
export const ArticleScreenshots = () => import('../../components/Edit/Article/ArticleScreenshots.vue' /* webpackChunkName: "components/article-screenshots" */).then(c => wrapFunctional(c.default || c))
export const ArticleSpellCheck = () => import('../../components/Edit/Article/ArticleSpellCheck.vue' /* webpackChunkName: "components/article-spell-check" */).then(c => wrapFunctional(c.default || c))
export const ArticleTop = () => import('../../components/Edit/Article/ArticleTop.vue' /* webpackChunkName: "components/article-top" */).then(c => wrapFunctional(c.default || c))
export const ArticleVersions = () => import('../../components/Edit/Article/ArticleVersions.vue' /* webpackChunkName: "components/article-versions" */).then(c => wrapFunctional(c.default || c))
export const Hero = () => import('../../components/Edit/Article/Hero.vue' /* webpackChunkName: "components/hero" */).then(c => wrapFunctional(c.default || c))
export const LivescoreEditor = () => import('../../components/Edit/Article/LivescoreEditor.vue' /* webpackChunkName: "components/livescore-editor" */).then(c => wrapFunctional(c.default || c))
export const MultipleImageCropper = () => import('../../components/Edit/Article/MultipleImageCropper.vue' /* webpackChunkName: "components/multiple-image-cropper" */).then(c => wrapFunctional(c.default || c))
export const ParentIdEditor = () => import('../../components/Edit/Article/ParentIdEditor.vue' /* webpackChunkName: "components/parent-id-editor" */).then(c => wrapFunctional(c.default || c))
export const SpeechEditor = () => import('../../components/Edit/Article/SpeechEditor.vue' /* webpackChunkName: "components/speech-editor" */).then(c => wrapFunctional(c.default || c))
export const SubBrand = () => import('../../components/Edit/Article/SubBrand.vue' /* webpackChunkName: "components/sub-brand" */).then(c => wrapFunctional(c.default || c))
export const ArticlesByAuthor = () => import('../../components/Edit/Author/ArticlesByAuthor.vue' /* webpackChunkName: "components/articles-by-author" */).then(c => wrapFunctional(c.default || c))
export const UserByAuthor = () => import('../../components/Edit/Author/UserByAuthor.vue' /* webpackChunkName: "components/user-by-author" */).then(c => wrapFunctional(c.default || c))
export const DocContent = () => import('../../components/Edit/Doc/DocContent.vue' /* webpackChunkName: "components/doc-content" */).then(c => wrapFunctional(c.default || c))
export const DocEditorTopBar = () => import('../../components/Edit/Doc/DocEditorTopBar.vue' /* webpackChunkName: "components/doc-editor-top-bar" */).then(c => wrapFunctional(c.default || c))
export const DocHead = () => import('../../components/Edit/Doc/DocHead.vue' /* webpackChunkName: "components/doc-head" */).then(c => wrapFunctional(c.default || c))
export const DocLead = () => import('../../components/Edit/Doc/DocLead.vue' /* webpackChunkName: "components/doc-lead" */).then(c => wrapFunctional(c.default || c))
export const Clipboard = () => import('../../components/Edit/Frontpage/Clipboard.vue' /* webpackChunkName: "components/clipboard" */).then(c => wrapFunctional(c.default || c))
export const Notifications = () => import('../../components/Edit/Frontpage/Notifications.vue' /* webpackChunkName: "components/notifications" */).then(c => wrapFunctional(c.default || c))
export const TopBar = () => import('../../components/Edit/Frontpage/TopBar.vue' /* webpackChunkName: "components/top-bar" */).then(c => wrapFunctional(c.default || c))
export const ListingBoxArticle = () => import('../../components/Edit/ListingBox/ListingBoxArticle.vue' /* webpackChunkName: "components/listing-box-article" */).then(c => wrapFunctional(c.default || c))
export const ListingBoxArticleGroup = () => import('../../components/Edit/ListingBox/ListingBoxArticleGroup.vue' /* webpackChunkName: "components/listing-box-article-group" */).then(c => wrapFunctional(c.default || c))
export const ListingBoxPreview = () => import('../../components/Edit/ListingBox/ListingBoxPreview.vue' /* webpackChunkName: "components/listing-box-preview" */).then(c => wrapFunctional(c.default || c))
export const EditScheduleItemModal = () => import('../../components/Edit/Schedule/EditScheduleItemModal.vue' /* webpackChunkName: "components/edit-schedule-item-modal" */).then(c => wrapFunctional(c.default || c))
export const AbTest = () => import('../../components/Edit/UniqueGroup/AbTest.vue' /* webpackChunkName: "components/ab-test" */).then(c => wrapFunctional(c.default || c))
export const After = () => import('../../components/Edit/UniqueGroup/After.vue' /* webpackChunkName: "components/after" */).then(c => wrapFunctional(c.default || c))
export const CustomTag = () => import('../../components/Edit/UniqueGroup/CustomTag.vue' /* webpackChunkName: "components/custom-tag" */).then(c => wrapFunctional(c.default || c))
export const DateEditor = () => import('../../components/Edit/UniqueGroup/DateEditor.vue' /* webpackChunkName: "components/date-editor" */).then(c => wrapFunctional(c.default || c))
export const Direkt36 = () => import('../../components/Edit/UniqueGroup/Direkt36.vue' /* webpackChunkName: "components/direkt36" */).then(c => wrapFunctional(c.default || c))
export const DisclaimerList = () => import('../../components/Edit/UniqueGroup/DisclaimerList.vue' /* webpackChunkName: "components/disclaimer-list" */).then(c => wrapFunctional(c.default || c))
export const Flipbook = () => import('../../components/Edit/UniqueGroup/Flipbook.vue' /* webpackChunkName: "components/flipbook" */).then(c => wrapFunctional(c.default || c))
export const FrontpageWidget = () => import('../../components/Edit/UniqueGroup/FrontpageWidget.vue' /* webpackChunkName: "components/frontpage-widget" */).then(c => wrapFunctional(c.default || c))
export const GraphQl = () => import('../../components/Edit/UniqueGroup/GraphQl.vue' /* webpackChunkName: "components/graph-ql" */).then(c => wrapFunctional(c.default || c))
export const HoldblogRecommendation = () => import('../../components/Edit/UniqueGroup/HoldblogRecommendation.vue' /* webpackChunkName: "components/holdblog-recommendation" */).then(c => wrapFunctional(c.default || c))
export const ImpressPartners = () => import('../../components/Edit/UniqueGroup/ImpressPartners.vue' /* webpackChunkName: "components/impress-partners" */).then(c => wrapFunctional(c.default || c))
export const KeyEditor = () => import('../../components/Edit/UniqueGroup/KeyEditor.vue' /* webpackChunkName: "components/key-editor" */).then(c => wrapFunctional(c.default || c))
export const KeyValue = () => import('../../components/Edit/UniqueGroup/KeyValue.vue' /* webpackChunkName: "components/key-value" */).then(c => wrapFunctional(c.default || c))
export const LiveScore = () => import('../../components/Edit/UniqueGroup/LiveScore.vue' /* webpackChunkName: "components/live-score" */).then(c => wrapFunctional(c.default || c))
export const MenuList = () => import('../../components/Edit/UniqueGroup/MenuList.vue' /* webpackChunkName: "components/menu-list" */).then(c => wrapFunctional(c.default || c))
export const OlimpiaData = () => import('../../components/Edit/UniqueGroup/OlimpiaData.vue' /* webpackChunkName: "components/olimpia-data" */).then(c => wrapFunctional(c.default || c))
export const PrArticle = () => import('../../components/Edit/UniqueGroup/PrArticle.vue' /* webpackChunkName: "components/pr-article" */).then(c => wrapFunctional(c.default || c))
export const Search = () => import('../../components/Edit/UniqueGroup/Search.vue' /* webpackChunkName: "components/search" */).then(c => wrapFunctional(c.default || c))
export const SponsoredBox = () => import('../../components/Edit/UniqueGroup/SponsoredBox.vue' /* webpackChunkName: "components/sponsored-box" */).then(c => wrapFunctional(c.default || c))
export const SponsoredLine = () => import('../../components/Edit/UniqueGroup/SponsoredLine.vue' /* webpackChunkName: "components/sponsored-line" */).then(c => wrapFunctional(c.default || c))
export const TranstelexDonate = () => import('../../components/Edit/UniqueGroup/TranstelexDonate.vue' /* webpackChunkName: "components/transtelex-donate" */).then(c => wrapFunctional(c.default || c))
export const TrendingTags = () => import('../../components/Edit/UniqueGroup/TrendingTags.vue' /* webpackChunkName: "components/trending-tags" */).then(c => wrapFunctional(c.default || c))
export const UniqueImage = () => import('../../components/Edit/UniqueGroup/UniqueImage.vue' /* webpackChunkName: "components/unique-image" */).then(c => wrapFunctional(c.default || c))
export const YouthEditor = () => import('../../components/Edit/UniqueGroup/YouthEditor.vue' /* webpackChunkName: "components/youth-editor" */).then(c => wrapFunctional(c.default || c))
export const AuthorByUser = () => import('../../components/Edit/User/AuthorByUser.vue' /* webpackChunkName: "components/author-by-user" */).then(c => wrapFunctional(c.default || c))
export const HrRolesModal = () => import('../../components/Edit/User/HrRolesModal.vue' /* webpackChunkName: "components/hr-roles-modal" */).then(c => wrapFunctional(c.default || c))
export const ScheduleEdit = () => import('../../components/Edit/User/ScheduleEdit.vue' /* webpackChunkName: "components/schedule-edit" */).then(c => wrapFunctional(c.default || c))
export const UserPermissionEdit = () => import('../../components/Edit/User/UserPermissionEdit.vue' /* webpackChunkName: "components/user-permission-edit" */).then(c => wrapFunctional(c.default || c))
export const Scale10Choice = () => import('../../components/Edit/Voting/Scale10Choice.vue' /* webpackChunkName: "components/scale10-choice" */).then(c => wrapFunctional(c.default || c))
export const SimpleChoice = () => import('../../components/Edit/Voting/SimpleChoice.vue' /* webpackChunkName: "components/simple-choice" */).then(c => wrapFunctional(c.default || c))
export const TippChoice = () => import('../../components/Edit/Voting/TippChoice.vue' /* webpackChunkName: "components/tipp-choice" */).then(c => wrapFunctional(c.default || c))
export const VotingChoice = () => import('../../components/Edit/Voting/VotingChoice.vue' /* webpackChunkName: "components/voting-choice" */).then(c => wrapFunctional(c.default || c))
export const VotingChoiceResult = () => import('../../components/Edit/Voting/VotingChoiceResult.vue' /* webpackChunkName: "components/voting-choice-result" */).then(c => wrapFunctional(c.default || c))
export const OfflineAlert = () => import('../../components/Layout/Alerts/OfflineAlert.vue' /* webpackChunkName: "components/offline-alert" */).then(c => wrapFunctional(c.default || c))
export const ReloadNeededAlert = () => import('../../components/Layout/Alerts/ReloadNeededAlert.vue' /* webpackChunkName: "components/reload-needed-alert" */).then(c => wrapFunctional(c.default || c))
export const SyncFailedAlert = () => import('../../components/Layout/Alerts/SyncFailedAlert.vue' /* webpackChunkName: "components/sync-failed-alert" */).then(c => wrapFunctional(c.default || c))
export const BroadcastModal = () => import('../../components/Layout/Modals/BroadcastModal.vue' /* webpackChunkName: "components/broadcast-modal" */).then(c => wrapFunctional(c.default || c))
export const EmergencyHelpModal = () => import('../../components/Layout/Modals/EmergencyHelpModal.vue' /* webpackChunkName: "components/emergency-help-modal" */).then(c => wrapFunctional(c.default || c))
export const MessagesModal = () => import('../../components/Layout/Modals/MessagesModal.vue' /* webpackChunkName: "components/messages-modal" */).then(c => wrapFunctional(c.default || c))
export const PersonOnDutyModal = () => import('../../components/Layout/Modals/PersonOnDutyModal.vue' /* webpackChunkName: "components/person-on-duty-modal" */).then(c => wrapFunctional(c.default || c))
export const PipModal = () => import('../../components/Layout/Modals/PipModal.vue' /* webpackChunkName: "components/pip-modal" */).then(c => wrapFunctional(c.default || c))
export const ScreenshotModal = () => import('../../components/Layout/Modals/ScreenshotModal.vue' /* webpackChunkName: "components/screenshot-modal" */).then(c => wrapFunctional(c.default || c))
export const ShortCutsModal = () => import('../../components/Layout/Modals/ShortCutsModal.vue' /* webpackChunkName: "components/short-cuts-modal" */).then(c => wrapFunctional(c.default || c))
export const ArticleListItem = () => import('../../components/Lists/Article/ArticleListItem.vue' /* webpackChunkName: "components/article-list-item" */).then(c => wrapFunctional(c.default || c))
export const NewMirrorModal = () => import('../../components/Lists/Article/NewMirrorModal.vue' /* webpackChunkName: "components/new-mirror-modal" */).then(c => wrapFunctional(c.default || c))
export const ListFilter = () => import('../../components/Lists/Filters/ListFilter.vue' /* webpackChunkName: "components/list-filter" */).then(c => wrapFunctional(c.default || c))
export const BackupCodeGenerator = () => import('../../components/Login/google-authenticator/BackupCodeGenerator.vue' /* webpackChunkName: "components/backup-code-generator" */).then(c => wrapFunctional(c.default || c))
export const SecondFactorLogin = () => import('../../components/Login/google-authenticator/SecondFactorLogin.vue' /* webpackChunkName: "components/second-factor-login" */).then(c => wrapFunctional(c.default || c))
export const SecretQr = () => import('../../components/Login/google-authenticator/SecretQr.vue' /* webpackChunkName: "components/secret-qr" */).then(c => wrapFunctional(c.default || c))
export const ResetPassword = () => import('../../components/Login/reset-password/ResetPassword.vue' /* webpackChunkName: "components/reset-password" */).then(c => wrapFunctional(c.default || c))
export const ArticleTypeahead = () => import('../../components/Shared/Articles/ArticleTypeahead.vue' /* webpackChunkName: "components/article-typeahead" */).then(c => wrapFunctional(c.default || c))
export const BeamStat = () => import('../../components/Shared/Articles/BeamStat.vue' /* webpackChunkName: "components/beam-stat" */).then(c => wrapFunctional(c.default || c))
export const DynamicComponentRenderer = () => import('../../components/Shared/Articles/DynamicComponentRenderer.vue' /* webpackChunkName: "components/dynamic-component-renderer" */).then(c => wrapFunctional(c.default || c))
export const Facebook = () => import('../../components/Shared/Articles/Facebook.vue' /* webpackChunkName: "components/facebook" */).then(c => wrapFunctional(c.default || c))
export const LatestArticlesList = () => import('../../components/Shared/Articles/LatestArticlesList.vue' /* webpackChunkName: "components/latest-articles-list" */).then(c => wrapFunctional(c.default || c))
export const YoutubeEmbed = () => import('../../components/Shared/Articles/YoutubeEmbed.vue' /* webpackChunkName: "components/youtube-embed" */).then(c => wrapFunctional(c.default || c))
export const DateLocale = () => import('../../components/Shared/Date/DateLocale.vue' /* webpackChunkName: "components/date-locale" */).then(c => wrapFunctional(c.default || c))
export const Live = () => import('../../components/Shared/FrontendComponents/Live.vue' /* webpackChunkName: "components/live" */).then(c => wrapFunctional(c.default || c))
export const Pagination = () => import('../../components/Shared/FrontendComponents/Pagination.vue' /* webpackChunkName: "components/pagination" */).then(c => wrapFunctional(c.default || c))
export const ThemeWidget = () => import('../../components/Shared/FrontendComponents/ThemeWidget.vue' /* webpackChunkName: "components/theme-widget" */).then(c => wrapFunctional(c.default || c))
export const FrontendLayout = () => import('../../components/Shared/FrontendLayout/FrontendLayout.vue' /* webpackChunkName: "components/frontend-layout" */).then(c => wrapFunctional(c.default || c))
export const ConfirmModal = () => import('../../components/Shared/Modals/ConfirmModal.vue' /* webpackChunkName: "components/confirm-modal" */).then(c => wrapFunctional(c.default || c))
export const DangerBrowserModal = () => import('../../components/Shared/Modals/DangerBrowserModal.vue' /* webpackChunkName: "components/danger-browser-modal" */).then(c => wrapFunctional(c.default || c))
export const AlriteDisclaimerSelector = () => import('../../components/Shared/TechnicalEmbeds/AlriteDisclaimerSelector.vue' /* webpackChunkName: "components/alrite-disclaimer-selector" */).then(c => wrapFunctional(c.default || c))
export const Direkt36VariantSelector = () => import('../../components/Shared/TechnicalEmbeds/Direkt36VariantSelector.vue' /* webpackChunkName: "components/direkt36-variant-selector" */).then(c => wrapFunctional(c.default || c))
export const Election2022Map = () => import('../../components/Shared/TechnicalEmbeds/Election2022Map.vue' /* webpackChunkName: "components/election2022-map" */).then(c => wrapFunctional(c.default || c))
export const EpisodeSelector = () => import('../../components/Shared/TechnicalEmbeds/EpisodeSelector.vue' /* webpackChunkName: "components/episode-selector" */).then(c => wrapFunctional(c.default || c))
export const GeneralDisclaimerSelector = () => import('../../components/Shared/TechnicalEmbeds/GeneralDisclaimerSelector.vue' /* webpackChunkName: "components/general-disclaimer-selector" */).then(c => wrapFunctional(c.default || c))
export const QuizVariantSelector = () => import('../../components/Shared/TechnicalEmbeds/QuizVariantSelector.vue' /* webpackChunkName: "components/quiz-variant-selector" */).then(c => wrapFunctional(c.default || c))
export const TranstelexDonationSelector = () => import('../../components/Shared/TechnicalEmbeds/TranstelexDonationSelector.vue' /* webpackChunkName: "components/transtelex-donation-selector" */).then(c => wrapFunctional(c.default || c))
export const InlineAdEdit = () => import('../../components/Edit/Article/Ad/InlineAdEdit.vue' /* webpackChunkName: "components/inline-ad-edit" */).then(c => wrapFunctional(c.default || c))
export const FactCheck = () => import('../../components/Edit/Article/EditableFrontendComponents/FactCheck.vue' /* webpackChunkName: "components/fact-check" */).then(c => wrapFunctional(c.default || c))
export const Szepkilatas = () => import('../../components/Edit/Article/EditableFrontendComponents/Szepkilatas.vue' /* webpackChunkName: "components/szepkilatas" */).then(c => wrapFunctional(c.default || c))
export const ChildArticle = () => import('../../components/Edit/Article/LiveBlog/ChildArticle.vue' /* webpackChunkName: "components/child-article" */).then(c => wrapFunctional(c.default || c))
export const ChildArticleList = () => import('../../components/Edit/Article/LiveBlog/ChildArticleList.vue' /* webpackChunkName: "components/child-article-list" */).then(c => wrapFunctional(c.default || c))
export const Event = () => import('../../components/Edit/Article/LiveBlog/Event.vue' /* webpackChunkName: "components/event" */).then(c => wrapFunctional(c.default || c))
export const FastActiveToggle = () => import('../../components/Edit/Article/LiveBlog/FastActiveToggle.vue' /* webpackChunkName: "components/fast-active-toggle" */).then(c => wrapFunctional(c.default || c))
export const LiveBlogBlock = () => import('../../components/Edit/Article/LiveBlog/LiveBlogBlock.vue' /* webpackChunkName: "components/live-blog-block" */).then(c => wrapFunctional(c.default || c))
export const AdvancedImageImage = () => import('../../components/Edit/Article/Modals/AdvancedImageImage.vue' /* webpackChunkName: "components/advanced-image-image" */).then(c => wrapFunctional(c.default || c))
export const AdvancedImageModal = () => import('../../components/Edit/Article/Modals/AdvancedImageModal.vue' /* webpackChunkName: "components/advanced-image-modal" */).then(c => wrapFunctional(c.default || c))
export const ArticleModifiedModal = () => import('../../components/Edit/Article/Modals/ArticleModifiedModal.vue' /* webpackChunkName: "components/article-modified-modal" */).then(c => wrapFunctional(c.default || c))
export const ArticleVersionModal = () => import('../../components/Edit/Article/Modals/ArticleVersionModal.vue' /* webpackChunkName: "components/article-version-modal" */).then(c => wrapFunctional(c.default || c))
export const AuthorsModal = () => import('../../components/Edit/Article/Modals/AuthorsModal.vue' /* webpackChunkName: "components/authors-modal" */).then(c => wrapFunctional(c.default || c))
export const AvCodeModal = () => import('../../components/Edit/Article/Modals/AvCodeModal.vue' /* webpackChunkName: "components/av-code-modal" */).then(c => wrapFunctional(c.default || c))
export const BackgroundArticleChangedModal = () => import('../../components/Edit/Article/Modals/BackgroundArticleChangedModal.vue' /* webpackChunkName: "components/background-article-changed-modal" */).then(c => wrapFunctional(c.default || c))
export const ConfirmInactiveModal = () => import('../../components/Edit/Article/Modals/ConfirmInactiveModal.vue' /* webpackChunkName: "components/confirm-inactive-modal" */).then(c => wrapFunctional(c.default || c))
export const ConfirmNotLiveLiveBlogModal = () => import('../../components/Edit/Article/Modals/ConfirmNotLiveLiveBlogModal.vue' /* webpackChunkName: "components/confirm-not-live-live-blog-modal" */).then(c => wrapFunctional(c.default || c))
export const CoverVideoModal = () => import('../../components/Edit/Article/Modals/CoverVideoModal.vue' /* webpackChunkName: "components/cover-video-modal" */).then(c => wrapFunctional(c.default || c))
export const DraftFoundModal = () => import('../../components/Edit/Article/Modals/DraftFoundModal.vue' /* webpackChunkName: "components/draft-found-modal" */).then(c => wrapFunctional(c.default || c))
export const HoldBlogModal = () => import('../../components/Edit/Article/Modals/HoldBlogModal.vue' /* webpackChunkName: "components/hold-blog-modal" */).then(c => wrapFunctional(c.default || c))
export const LiveBlogEventModal = () => import('../../components/Edit/Article/Modals/LiveBlogEventModal.vue' /* webpackChunkName: "components/live-blog-event-modal" */).then(c => wrapFunctional(c.default || c))
export const LongChapterModal = () => import('../../components/Edit/Article/Modals/LongChapterModal.vue' /* webpackChunkName: "components/long-chapter-modal" */).then(c => wrapFunctional(c.default || c))
export const MainSuperTagsModal = () => import('../../components/Edit/Article/Modals/MainSuperTagsModal.vue' /* webpackChunkName: "components/main-super-tags-modal" */).then(c => wrapFunctional(c.default || c))
export const PreSelectionModal = () => import('../../components/Edit/Article/Modals/PreSelectionModal.vue' /* webpackChunkName: "components/pre-selection-modal" */).then(c => wrapFunctional(c.default || c))
export const QuickVoteModal = () => import('../../components/Edit/Article/Modals/QuickVoteModal.vue' /* webpackChunkName: "components/quick-vote-modal" */).then(c => wrapFunctional(c.default || c))
export const SuperTagsModal = () => import('../../components/Edit/Article/Modals/SuperTagsModal.vue' /* webpackChunkName: "components/super-tags-modal" */).then(c => wrapFunctional(c.default || c))
export const TagsModal = () => import('../../components/Edit/Article/Modals/TagsModal.vue' /* webpackChunkName: "components/tags-modal" */).then(c => wrapFunctional(c.default || c))
export const TechnicalEmbedModal = () => import('../../components/Edit/Article/Modals/TechnicalEmbedModal.vue' /* webpackChunkName: "components/technical-embed-modal" */).then(c => wrapFunctional(c.default || c))
export const ToLiveBlogModal = () => import('../../components/Edit/Article/Modals/ToLiveBlogModal.vue' /* webpackChunkName: "components/to-live-blog-modal" */).then(c => wrapFunctional(c.default || c))
export const VotingModal = () => import('../../components/Edit/Article/Modals/VotingModal.vue' /* webpackChunkName: "components/voting-modal" */).then(c => wrapFunctional(c.default || c))
export const GridChoice = () => import('../../components/Edit/Article/Quiz/GridChoice.vue' /* webpackChunkName: "components/grid-choice" */).then(c => wrapFunctional(c.default || c))
export const InlineQuestionEdit = () => import('../../components/Edit/Article/Quiz/InlineQuestionEdit.vue' /* webpackChunkName: "components/inline-question-edit" */).then(c => wrapFunctional(c.default || c))
export const QuestionCloseDatePicker = () => import('../../components/Edit/Article/Quiz/QuestionCloseDatePicker.vue' /* webpackChunkName: "components/question-close-date-picker" */).then(c => wrapFunctional(c.default || c))
export const ArticlePicker = () => import('../../components/Edit/Frontpage/BoxEditor/ArticlePicker.vue' /* webpackChunkName: "components/article-picker" */).then(c => wrapFunctional(c.default || c))
export const BoxEditor = () => import('../../components/Edit/Frontpage/BoxEditor/BoxEditor.vue' /* webpackChunkName: "components/box-editor" */).then(c => wrapFunctional(c.default || c))
export const BoxSettings = () => import('../../components/Edit/Frontpage/BoxEditor/BoxSettings.vue' /* webpackChunkName: "components/box-settings" */).then(c => wrapFunctional(c.default || c))
export const ConnectedData = () => import('../../components/Edit/Frontpage/BoxEditor/ConnectedData.vue' /* webpackChunkName: "components/connected-data" */).then(c => wrapFunctional(c.default || c))
export const HighlightSettings = () => import('../../components/Edit/Frontpage/BoxEditor/HighlightSettings.vue' /* webpackChunkName: "components/highlight-settings" */).then(c => wrapFunctional(c.default || c))
export const ImageEditor = () => import('../../components/Edit/Frontpage/BoxEditor/ImageEditor.vue' /* webpackChunkName: "components/image-editor" */).then(c => wrapFunctional(c.default || c))
export const ItemIcon = () => import('../../components/Edit/Frontpage/BoxEditor/ItemIcon.vue' /* webpackChunkName: "components/item-icon" */).then(c => wrapFunctional(c.default || c))
export const PrCode = () => import('../../components/Edit/Frontpage/BoxEditor/PrCode.vue' /* webpackChunkName: "components/pr-code" */).then(c => wrapFunctional(c.default || c))
export const BoxMeta = () => import('../../components/Edit/Frontpage/DynamicBlock/BoxMeta.vue' /* webpackChunkName: "components/box-meta" */).then(c => wrapFunctional(c.default || c))
export const BreakingSwitch = () => import('../../components/Edit/Frontpage/DynamicBlock/BreakingSwitch.vue' /* webpackChunkName: "components/breaking-switch" */).then(c => wrapFunctional(c.default || c))
export const DynamicBlock = () => import('../../components/Edit/Frontpage/DynamicBlock/DynamicBlock.vue' /* webpackChunkName: "components/dynamic-block" */).then(c => wrapFunctional(c.default || c))
export const DynamicBox = () => import('../../components/Edit/Frontpage/DynamicBlock/DynamicBox.vue' /* webpackChunkName: "components/dynamic-box" */).then(c => wrapFunctional(c.default || c))
export const DynamicGroup = () => import('../../components/Edit/Frontpage/DynamicBlock/DynamicGroup.vue' /* webpackChunkName: "components/dynamic-group" */).then(c => wrapFunctional(c.default || c))
export const DynamicPlaceholder = () => import('../../components/Edit/Frontpage/DynamicBlock/DynamicPlaceholder.vue' /* webpackChunkName: "components/dynamic-placeholder" */).then(c => wrapFunctional(c.default || c))
export const FrontpageContainer = () => import('../../components/Edit/Frontpage/DynamicBlock/FrontpageContainer.vue' /* webpackChunkName: "components/frontpage-container" */).then(c => wrapFunctional(c.default || c))
export const ItemConfig = () => import('../../components/Edit/Frontpage/DynamicBlock/ItemConfig.vue' /* webpackChunkName: "components/item-config" */).then(c => wrapFunctional(c.default || c))
export const ItemConfigMenu = () => import('../../components/Edit/Frontpage/DynamicBlock/ItemConfigMenu.vue' /* webpackChunkName: "components/item-config-menu" */).then(c => wrapFunctional(c.default || c))
export const PlaceholderContainer = () => import('../../components/Edit/Frontpage/DynamicBlock/PlaceholderContainer.vue' /* webpackChunkName: "components/placeholder-container" */).then(c => wrapFunctional(c.default || c))
export const ArticleModified = () => import('../../components/Edit/Frontpage/NotificationItems/ArticleModified.vue' /* webpackChunkName: "components/article-modified" */).then(c => wrapFunctional(c.default || c))
export const AuthorFilter = () => import('../../components/Lists/Filters/CustomFilters/AuthorFilter.vue' /* webpackChunkName: "components/author-filter" */).then(c => wrapFunctional(c.default || c))
export const TagFilter = () => import('../../components/Lists/Filters/CustomFilters/TagFilter.vue' /* webpackChunkName: "components/tag-filter" */).then(c => wrapFunctional(c.default || c))
export const AdvancedImage = () => import('../../components/Shared/Articles/dynamic-components/AdvancedImage.vue' /* webpackChunkName: "components/advanced-image" */).then(c => wrapFunctional(c.default || c))
export const ArticleSelector = () => import('../../components/Shared/Modals/ArticleSelector/ArticleSelector.vue' /* webpackChunkName: "components/article-selector" */).then(c => wrapFunctional(c.default || c))
export const EndEvent = () => import('../../components/Edit/Article/LiveBlog/Widgets/EndEvent.vue' /* webpackChunkName: "components/end-event" */).then(c => wrapFunctional(c.default || c))
export const GoalEvent = () => import('../../components/Edit/Article/LiveBlog/Widgets/GoalEvent.vue' /* webpackChunkName: "components/goal-event" */).then(c => wrapFunctional(c.default || c))
export const HalfEvent = () => import('../../components/Edit/Article/LiveBlog/Widgets/HalfEvent.vue' /* webpackChunkName: "components/half-event" */).then(c => wrapFunctional(c.default || c))
export const OwnGoalEvent = () => import('../../components/Edit/Article/LiveBlog/Widgets/OwnGoalEvent.vue' /* webpackChunkName: "components/own-goal-event" */).then(c => wrapFunctional(c.default || c))
export const RedCardEvent = () => import('../../components/Edit/Article/LiveBlog/Widgets/RedCardEvent.vue' /* webpackChunkName: "components/red-card-event" */).then(c => wrapFunctional(c.default || c))
export const StartEvent = () => import('../../components/Edit/Article/LiveBlog/Widgets/StartEvent.vue' /* webpackChunkName: "components/start-event" */).then(c => wrapFunctional(c.default || c))
export const SubstitutionEvent = () => import('../../components/Edit/Article/LiveBlog/Widgets/SubstitutionEvent.vue' /* webpackChunkName: "components/substitution-event" */).then(c => wrapFunctional(c.default || c))
export const YellowCardEvent = () => import('../../components/Edit/Article/LiveBlog/Widgets/YellowCardEvent.vue' /* webpackChunkName: "components/yellow-card-event" */).then(c => wrapFunctional(c.default || c))
export const YellowRedCardEvent = () => import('../../components/Edit/Article/LiveBlog/Widgets/YellowRedCardEvent.vue' /* webpackChunkName: "components/yellow-red-card-event" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
