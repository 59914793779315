export default async(ctx) => {
  async function initData() {
    await ctx.$frontendRepository.init().then((response) => {
      ctx.store.commit('commonData/setFrontendSetup', response.frontendSetup)
      ctx.store.commit('commonData/setComponentSetup', response.componentSetup)
      ctx.store.commit('commonData/setExchangeRates', response.exchangeRates)
      ctx.store.commit('commonData/setWeatherData', response.weatherData)
      // ctx.store.commit('menu/setMainMenuItems', response.mainMenuItems)
      // ctx.store.commit('menu/setSidebarMenuItems', response.sidebarMenuItems)
      // ctx.store.commit('menu/setHeadingMenuItems', response.headingMenuItems)
      // ctx.store.commit('menu/setFooterHighlightMenuItems', response.footerHighlightMenuItems)
      // ctx.store.commit('menu/setFooterNormalMenuItems', response.footerNormalMenuItems)
      // ctx.store.commit('menu/setFooterAppMenuItems', response.footerAppMenuItems)
      // ctx.store.commit('menu/setFooterSocialMenuItems', response.footerSocialMenuItems)
      ctx.store.commit('commonData/setPipStatus', response.pipStatus)
      // ctx.store.commit('sponsoredLine/setSponsoredLines', response.sponsoredLines)
      // ctx.store.commit('sponsoredLine/setSponsoredBoxes', response.sponsoredBoxes)
      // ctx.store.commit('abTest/setTests', response.abTests)
      // ctx.store.commit('commonData/setDwPromo', response.dwPromo)
      // ctx.store.commit('ads/setAds', response.ads)
    })
  }

  // async function refreshData() {
  //   await ctx.$frontendRepository.refresh().then((response) => {
  //     ctx.store.commit('commonData/setComponentSetup', response.componentSetup)
  //     ctx.store.commit('commonData/setExchangeRates', response.exchangeRates)
  //     ctx.store.commit('commonData/setWeatherData', response.weatherData)
  //     ctx.store.commit('commonData/setPipStatus', response.pipStatus)
  //   })
  //   setTimeout(async() => await refreshData(), 15000)
  // }

  // process.client
  //   ? setTimeout(async() => await refreshData(), 15000)
  //   : await initData()
  if (process.server) {
    await initData()
  }
}
