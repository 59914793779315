export default async function(context) {
  if (context.route.path.match(/\/([\S]+?)\/(\d{4,4})\/(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/([\S]+?)$/)) {
    const pathArray = context.route.path.split('/')
    const slug = pathArray[pathArray.length - 1]

    const article = await context.$articleRepository.getBySlug(slug)

    if (article.type === 'liveblog') {
      console.log('frontend-redirect to liveblog edit', slug, article.type)
      context.redirect({
        name: 'live_blog_edit',
        params: {
          slug: article.slug,
        },
      })
    }

    if (article.type === 'liveblogpost') {
      console.log('frontend-redirect to liveblog item edit', slug)
      context.redirect({
        name: 'live_blog_child_edit',
        params: {
          parentSlug: article.parent.slug,
          slug: article.slug,
        },
      })
      return
    }

    console.log('frontend-redirect to simple article edit', slug, article.type)
    context.redirect({
      name: 'article_edit',
      params: {
        slug: article.slug,
      },
    })
  }
}
