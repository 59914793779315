import { render, staticRenderFns } from "./default.vue?vue&type=template&id=609ad086"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=609ad086&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LeftLoginScreen: require('/app/components/Login/LeftLoginScreen.vue').default,RightLoginScreen: require('/app/components/Login/RightLoginScreen.vue').default,TopHeader: require('/app/components/Layout/TopHeader.vue').default,AdBlockBlock: require('/app/components/AdBlockBlock.vue').default,DangerBrowserModal: require('/app/components/Shared/Modals/DangerBrowserModal.vue').default,CookiesDeleteAnimation: require('/app/components/Layout/CookiesDeleteAnimation.vue').default,Sidebar: require('/app/components/Layout/Sidebar.vue').default})
