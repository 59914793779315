export default () => ({
  truncate(string, length, useWordBoundary) {
    if (!string || string.length <= length) {
      return string
    }
    const subString = string.substring(0, length - 1)
    return (useWordBoundary
      ? subString.substring(0, subString.lastIndexOf(' '))
      : subString) + '...'
  },
})
