export default ({ store, $cookies }) => {
  if ($cookies.get('settings')) {
    store.commit('setSettings', $cookies.get('settings'))
  }
  store.subscribe((mutation) => {
    if (mutation.type === 'setSettingsField') {
      $cookies.set('settings', store.getters.getSettings, {
        maxAge: 60 * 60 * 24 * 30,
      })
    }
  })
}
