
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      editMode: false,
      broadcastData: {
        type: null,
        title: null,
        description: null,
        sender: null,
        showSender: null,
      },
      broadcastTypes: [
        { label: 'Kisüzem', value: 'default' },
        { label: 'Fontos', value: 'message' },
        { label: 'Hiba', value: 'error' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      broadcast: 'getBroadcast',
    }),
    showBroadcastModal: {
      get() {
        return this.$store.getters['layout/isShowBroadcastModal']
      },
      set(newValue) {
        this.$store.commit('layout/setShowBroadcastModal', newValue)
      },
    },
  },
  watch: {
    broadcast(newValue) {
      this.broadcastData = { ...newValue }
    },
    showBroadcastModal(newValue) {
      if (newValue) {
        this.editMode = false
      }
    },
  },
  methods: {
    setBroadcastData() {
      this.broadcastData.sender = this.$store.getters['auth/getLoggedInUserName']
      this.$store.dispatch('setBroadcast', this.broadcastData)
      this.editMode = false
    },
    handleConfirm() {
      this.editMode ? this.setBroadcastData() : this.editMode = true
    },
  },
}
