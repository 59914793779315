import { LockManager } from '~/socket/managers/LockManager'
import { FrontpageManager } from '~/socket/managers/FrontpageManager'
import { RootManager } from '~/socket/managers/RootManager'

export default async(ctx, inject) => {
  // WROKAROUND: https://github.com/richardeschloss/nuxt-socket-io/issues/169#issuecomment-734709122
  ctx.$store = ctx.store
  const tabId = ctx.$store.state.tabId
  const rootManager = await new RootManager(
    ctx,
    tabId,
    ctx.$config.commitShortSha,
  )
  inject('rootManager', rootManager)

  const lockManager = new LockManager(ctx, tabId)
  inject('lockManager', lockManager)

  const frontpageManager = await new FrontpageManager(ctx, tabId)
  inject('frontpageManager', frontpageManager)
}
