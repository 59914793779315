import { render, staticRenderFns } from "./BroadcastModal.vue?vue&type=template&id=29d3c20d"
import script from "./BroadcastModal.vue?vue&type=script&lang=js"
export * from "./BroadcastModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TextInput: require('/app/components/Form/TextInput.vue').default,CheckboxInput: require('/app/components/Form/CheckboxInput.vue').default,PermissionCheck: require('/app/components/Shared/PermissionCheck.vue').default,ConfirmModal: require('/app/components/Shared/Modals/ConfirmModal.vue').default})
