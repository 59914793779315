import quizValidator from '../helpers/embeds/quiz/validators/quizValidator'

const getDefaultState = () => {
  return {
    fetching: false, // Folyamatban van-e a quiz lekérdezése az API-ból
    loading: false,
    hasLoadError: false, // Sikerült-e a kvízt betölteni
    saving: false, // Folyamatban van-e a quiz mentése
    quiz: null,
    questionValidationErrors: {}, // a kérdések validációs eredményét tároljuk ebben
  }
}

export const state = getDefaultState()

export const mutations = {
  reset(state) {
    console.log('quizEdit reset run!')
    // Merge rather than replace so we don't lose observers
    // https://github.com/vuejs/vuex/issues/1118
    Object.assign(state, getDefaultState())
  },
  initEmptyQuiz(state) {
    state.quiz = {}
  },
  setFetching(state, isFetching) {
    state.fetching = isFetching
  },
  setLoading(state, isLoading) {
    state.loading = isLoading
  },
  setLoadError(state, hasLoadError) {
    state.hasLoadError = hasLoadError
  },
  setSaving(state, isSaving) {
    state.saving = isSaving
  },
  setQuiz(state, quiz) {
    state.quiz = quiz
  },
  setQuestion(state, question) {
    // 0. ha még nincs quiz, akkor hozzáadjuk
    if (!state.quiz || !state.quiz.questions) {
      state.quiz = {}
      state.quiz.questions = [question]
      return
    }

    // 1. kikeresni a questiont, hogy hozzá van-e már adva
    const questionToUpdateIndex = state.quiz.questions.findIndex((storedQuestion) => {
      // Ha a sima id-k egyeznek
      if (question._id && storedQuestion._id === question._id) {
        return true
      }

      // Ha a temprorary id-k egyeznek
      if (question.temporaryId && storedQuestion.temporaryId === question.temporaryId) {
        return true
      }
      return false
    })

    if (questionToUpdateIndex >= 0) {
      state.quiz.questions[questionToUpdateIndex] = question
      return
    }

    // 2. ha nincs még a question benne a quiz-ben:
    state.quiz.questions.push(question)
  },
  removeQuestion(state, question) {
    // 0. ha még nincs quiz, akkor semmi dolgunk
    if (!state.quiz || !state.quiz.questions) {
      return
    }

    state.quiz.questions = state.quiz.questions.filter((storedQuestion) => {
      // Ha a sima id-k egyeznek
      if (question.id && storedQuestion.id === question.id) {
        return false
      }

      // Ha a temporary id-k egyeznek
      if (question.temporaryId && storedQuestion.temporaryId === question.temporaryId) {
        return false
      }
      return true
    })
  },
  removeQuestionValidationError(state, key) {
    delete state.questionValidationErrors[key]
  },
  setQuestionValidationErrors(state, errors) {
    for (const key in errors) {
      state.questionValidationErrors[key] = errors[key]
    }

    state.questionValidationErrors = JSON.parse(JSON.stringify(state.questionValidationErrors))
  },
}

export const actions = {
  async fetchQuizById({ commit, state }, quizId) {
    if (state.fetching) {
      console.log('A quiz lekérdezés már fut, ezért nem indítunk újat!')
      return
    }
    commit('setFetching', true)
    try {
      const responseBody = await window.$nuxt.$quizRepository.getQuiz(quizId)
      console.log('responseBody', responseBody)

      if (!responseBody?.data?.quiz) {
        throw new Error('Quiz data not found')
      }

      console.log('setFetching RESPONSE BODY', responseBody.data.quiz)
      commit('setQuiz', responseBody.data.quiz)
    } catch (e) {
      console.error('NaGYOnNaGY a BAj: nem sikerült a kvíz betöltése', e)
      window.$nuxt.$sentry.captureException(new Error('NaGYOnNaGY a BAj: nem sikerült a kvíz betöltése'))
      commit('setLoadError', true)
    } finally {
      commit('setFetching', false)
    }
  },
  validate({ commit, state }) {
    commit('setQuestionValidationErrors', quizValidator(state.quiz))
    return state.questionValidationErrors
  },
  async save({
    commit,
    state,
    dispatch,
  }) {
    if (state.quiz === null) {
      console.log('nem kell menteni!')
      return { success: true, status: 200 }
    }

    if (state.hasLoadError) {
      console.log('a szavazást nem lehet menteni, mert betölteni sem sikerült!')
      return { success: true, status: 200 }
    }

    if (!window.$nuxt?.$permissionChecker?.hasPermission('vote')) {
      console.log('nincs jogosultság a szavazáshoz, nem kell menteni')
      return { success: true, status: 200 }
    }

    dispatch('validate')
    if (Object.keys(state.questionValidationErrors).length !== 0) {
      console.log('validációs hiba van, nem lehet menteni', state.questionValidationErrors)
      const message = 'A szavazást nem sikerült menteni, nézd át a szavazást!'
      window.$nuxt.$bus.$emit('article-save-error', {
        type: 'subComponentSaveError',
        error: message,
      })

      return { success: false, message }
    }

    console.log('quizEdit store; save')
    commit('setLoading', true)
    commit('setSaving', true)

    const quizToSave = JSON.parse(JSON.stringify(state.quiz))

    // Kimentjük a temporary id-kat és a variantokat
    const temporaryIds = []
    const temporaryVariants = []
    quizToSave.questions.forEach((question) => {
      temporaryIds.push(question.temporaryId)
      delete question.temporaryId

      temporaryVariants.push(question.variant)
      delete question.variant

      if (!question._id) {
        delete question._id
      }
    })

    const saveQuizResponse = await window.$nuxt.$quizRepository.saveQuiz(quizToSave)

    if ((saveQuizResponse.errors && saveQuizResponse.errors !== [])
      || (saveQuizResponse.error && saveQuizResponse.error !== [])
    ) {
      window.$nuxt.$bus.$emit('article-save-error', {
        type: 'subComponentSaveError',
        error: 'A szavazást nem sikerült menteni!',
      })

      return { success: false, message: saveQuizResponse.errors }
    }

    const saveQuizResponseData = saveQuizResponse.data.addOrUpdateQuiz

    let index = 0
    // Visszatesszük a temporary id-kat és a variantokat
    saveQuizResponseData.questions.forEach((question) => {
      question.temporaryId = temporaryIds[index]
      question.variant = temporaryVariants[index]
      index++
    })

    commit('setQuiz', saveQuizResponseData)
    console.log('saveQuizResponse', saveQuizResponseData)
    commit('setLoading', false)
    commit('setSaving', false)
    return { success: true, status: 200 }
  },
}

export const getters = {
  getQuiz(state) {
    return state.quiz
  },
  isLoading(state) {
    return state.loading
  },
  isSaving(state) {
    return state.saving
  },
  // Temporary vagy igazi id alapján visszaadjuk a question-t a quiz-bóől
  getQuestionById: state => (id) => {
    if (!state.quiz.questions) {
      return null
    }
    return state.quiz.questions.find(quiz => quiz.temporaryId === id || quiz._id === id)
  },
}
