export const state = () => ({
  sidebar: null,
})

export const mutations = {
  toggle(state) {
    state.sidebar = !state.sidebar
  },
  setState(state, newValue) {
    state.sidebar = newValue
  },
}

export const getters = {
  getSidebarState(state) {
    return state.sidebar
  },
}
